<template>
  <div>
    <el-container>
      <el-header>
        <div>
          <div class="titleBtn flex">
            <div class="titleText">
              批量选择账户（{{ accountList.length }}/10）
            </div>
            <div>
              <el-button type="text" @click="emptyListFun">清空</el-button>
            </div>
          </div>
        </div>
      </el-header>
      <el-container>
        <el-aside
          ><div v-for="i in accountList.length + 1" class="forDiv">
            <div>{{ i }}</div>
          </div>
        </el-aside>
        <el-main>
          <div>
            <!-- 输入的值 -->
            <div>
              <div v-for="(item, index) in accountList">
                <div class="accountValue flex">
                  <div>{{ item }}</div>
                  <div>
                    <el-button type="text" @click="removeFun(index)"
                      >删除</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- 输入框 -->
            <div>
              <el-input
                v-model="input"
                placeholder="请输入账号id，每行一个账号id，敲击回车换行"
                clearable
                @keyup.enter.native="keyDownhandle()"
              ></el-input>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
  <div class="downBtn flex">
    <div><el-button @click="cnanelFun()">取消</el-button></div>
    <div><el-button @click="confirmFun()" type="primary">确定</el-button></div>
  </div>
</template>

<script>
import { reactive, toRefs, inject, watch, watchEffect } from 'vue'
import { ElMessage } from 'element-plus'
import { selectAccountIdIsNotExsit } from '@/request/new/videoBatchManagement'
export default {
  name: 'insertView',
  components: {},
  // 接收页面的传值
  props: ['data'],
  setup(props, context) {
    const state = reactive({
      // 账户列表
      accountList: [],
      // 输入框的值
      input: '',
      // 清空
      emptyListFun: () => {
        state.accountList = []
      },
      // 删除
      removeFun: (data) => {
        console.log('删除', data)
        console.log('删除', state.accountList[data])
        state.accountList.splice(data, 1)
        ElMessage({
          type: 'success',
          message: '删除成功',
        })
      },
      // 监听的回车
      keyDownhandle: () => {
        if (state.input.length == 0) {
          return ElMessage({
            type: 'error',
            message: '账户id不能为空',
          })
        }
        if (state.accountList.length == 10) {
          return ElMessage({
            type: 'error',
            message: '最多只能选择10个账户',
          })
        }
        state.accountList.forEach((element) => {
          if (state.input == element) {
            return ElMessage({
              type: 'error',
              message: '账户id:' + state.input + '已经存在',
            })
          }
        })

        const datas = {
          advertiserId: state.input,
        }
        selectAccountIdIsNotExsit(datas).then((res) => {
          if (res.code == 200) {
            state.accountList.push(state.input)
            state.input = ''
          }
        })
      },
      // 取消
      cnanelFun: () => {
        context.emit('getCannelFun')
      },
      // 确定
      confirmFun: () => {
        if (state.accountList.length == 0) {
          return ElMessage({
            type: 'error',
            message: '账户id不能为空',
          })
        }
        context.emit('getAccountListFun', state.accountList)
      },
    })

    return { ...toRefs(state) }
  },
}
</script>

<style lang="scss" scoped>
.el-header,
.el-footer {
  background-color: #f9fafa;
  color: #333;
  line-height: 30px;
  height: 40px;
  border: 1px solid #e9e9e9;
}

.el-aside {
  background-color: #ffffff;

  width: 50px;
  text-align: center;
  border: 1px solid #e9e9e9;
}

.el-main {
  background-color: #fcffff;
  color: #333;
  height: 600px;
  border: 1px solid #e9e9e9;
  padding: 0px;
}

body > .el-container {
  margin-bottom: 20px;
}

.titleBtn {
  justify-content: space-between;
}

.titleText {
  font-size: 16px;
  line-height: 40px;
}

.forDiv {
  height: 40px;
  line-height: 40px;
  border: 1px solid #ffffff;
}

.accountValue {
  height: 40px;
  padding: 0px 30px 0px 15px;
  font-size: 14px;
  border: 1px solid #ffffff;
  line-height: 40px;
  background-color: #f0f9eb;
  justify-content: space-between;
}

.accountValueChange {
  height: 40px;
  padding: 0px 30px 0px 15px;
  font-size: 14px;
  border: 1px solid #ffffff;
  line-height: 40px;
  background-color: #f0f0f0;
}

.downBtn {
  justify-content: flex-end;
  padding: 10px 10px 0px 15px;
  > div {
    padding-left: 30px;
  }
}
</style>
