<template>
  <div>
    <clickVideo
      :groupTitle="TitleGroupNameData"
      @getTitleGroupFun="getTitleGroupFun"
      @getTitleGroupIDFun="getTitleGroupIDFun2"
      :groupTitleSucai="TitleGroupSucaiData"
      @getSucaiFun="getSucaiFun"
      :changeSucai="sucaiList"
    ></clickVideo>
  </div>
  <div class="downBtn flex">
    <div>
      <showVideo
        :area="area"
        :sucaiData="sucaiList"
        @getsucaiDataChange="getsucaiDataChange"
      >
      </showVideo>
    </div>
    <!-- <div><el-button @click="cnanelFun()">取消</el-button></div> -->
    <div><el-button @click="confirmFun()" type="primary">确定</el-button></div>
  </div>
</template>

<script>
import { reactive, toRefs, inject, watch, watchEffect } from 'vue'
import { ElMessage } from 'element-plus'
import {
  selectGroupTitle,
  selectGroupTitleSucai,
  selectAndDeptId,
  queryOrganization,
  shareTitleGroup,
  selectAndNickName,
  selectAccountIdIsNotExsit,
} from '@/request/new/videoBatchManagement'
import ShowVideo from './showVideo.vue'
import clickVideo from '@/components/batch/clickVideo.vue'
export default {
  name: 'selectToutiaoVideo',
  components: { clickVideo, ShowVideo },
  // 接收页面的传值
  props: ['data', 'selectVideoData', 'waiChangeId', 'deliveryData'],
  setup(props, context) {
    const state = reactive({
      //所在区域
      area: 'in',
      // 外面改变的展示数据
      selectVideoData2: props.selectVideoData,
      //项目传递的数据
      isVersion: props.deliveryData.isVersion,

      // // 外面改变标题组ID的展示数据
      // waiChangeId: props.waiChangeId,
      // 选中的数据
      checkedCities: [],
      //素材数据
      sucaiList: [],
      // 搜索标题组的名称
      searchTitleName: '',
      // 查询的标题组数据
      TitleGroupNameData: '',
      // 素材组数据
      TitleGroupSucaiData: '',
      //选中的素材id
      groupTitleId: '',

      //接收<展示组件>传入的数据
      getsucaiDataChange: (index) => {
        console.log('接收【展示组件】传入的数据索引', index)
        state.sucaiList.splice(index, 1)
      },

      //接收组件传来查询标题组的数据
      getTitleGroupFun: (val) => {
        console.log('接收组件传来标题组的数据==>', val)
        state.searchTitleName = val.searchTitleName
        selectGroupTitleFun()
      },
      //接收组件传来需要获取素材数据
      getTitleGroupIDFun2: (val) => {
        state.groupTitleId = val.tid
        context.emit('getTitleId', state.groupTitleId)
        console.log('接收组件传来需要获取素材数据==>', state.groupTitleId)
        const datas = {
          tid: state.groupTitleId,
        }
        selectGroupTitleSucai(datas).then((res) => {
          state.TitleGroupSucaiData = res.data
        })
      },
      //接收组件传来的选中的素材
      getSucaiFun: (val) => {
        state.sucaiList = val
        if (state.sucaiList.length > 10 && state.isVersion == 0) {
          return ElMessage({
            type: 'error',
            message: '素材不能超过10个',
          })
        }
        if (state.sucaiList.length > 30 && state.isVersion == 1) {
          return ElMessage({
            type: 'error',
            message: '素材不能超过30个',
          })
        }
      },

      // 输入框的值
      input: '',
      // 确定
      confirmFun: () => {
        if (state.sucaiList.length > 10 && state.isVersion == 0) {
          return ElMessage({
            type: 'error',
            message: '素材不能超过10个',
          })
        } else {
          context.emit('getSelectSucaiVideo', state.sucaiList)
          console.log('往外界抛出去了10', state.sucaiList)
        }

        if (state.sucaiList.length > 30 && state.isVersion == 1) {
          return ElMessage({
            type: 'error',
            message: '素材不能超过30个',
          })
        } else {
          context.emit('getSelectSucaiVideo', state.sucaiList)
          console.log('往外界抛出去了30', state.sucaiList)
        }
      },
    })

    //监听外界删除选中素材
    watch(state.selectVideoData2, (newVal, OldVal) => {
      console.log('【外界视频组件】选中素材改变了，老值', OldVal)
      console.log('【外界视频组件】选中素材改变了,新值', newVal)
      state.sucaiList = newVal
    })

    // 搜索视频组名称
    const selectGroupTitleFun = (item) => {
      const datas = {}

      if (state.searchTitleName != '') {
        datas.name = state.searchTitleName
      }
      selectGroupTitle(datas).then((res) => {
        state.TitleGroupNameData = res.data
        if (item != '删除') {
          state.groupTitleId = ''
        }
        // console.log('state.groupTitleId ', state.groupTitleId)
      })
    }

    selectGroupTitleFun()

    return { ...toRefs(state) }
  },
}
</script>

<style lang="scss" scoped>
.el-header,
.el-footer {
  background-color: #f9fafa;
  color: #333;
  line-height: 30px;
  height: 40px;
  border: 1px solid #e9e9e9;
}

.el-aside {
  background-color: #ffffff;

  width: 50px;
  text-align: center;
  border: 1px solid #e9e9e9;
}

.el-main {
  background-color: #fcffff;
  color: #333;
  height: 600px;
  border: 1px solid #e9e9e9;
  padding: 0px;
}

body > .el-container {
  margin-bottom: 20px;
}

.top {
  height: 200px;
}

.titleBtn {
  justify-content: space-between;
}

.titleText {
  font-size: 16px;
  line-height: 40px;
}

.forDiv {
  height: 40px;
  line-height: 40px;
  border: 1px solid #ffffff;
}

.accountValue {
  height: 40px;
  padding: 0px 30px 0px 15px;
  font-size: 14px;
  border: 1px solid #ffffff;
  line-height: 40px;
  background-color: #f0f9eb;
  justify-content: space-between;
}

.accountValueChange {
  height: 40px;
  padding: 0px 30px 0px 15px;
  font-size: 14px;
  border: 1px solid #ffffff;
  line-height: 40px;
  background-color: #f0f0f0;
}

.downBtn {
  justify-content: flex-end;
  padding: 10px 10px 0px 15px;
  > div {
    padding-left: 30px;
  }
}
</style>
