

const wenan = [
  '穷小子为隐瞒身份成窝囊赘婿，却被美女总裁捉住：“叫老婆！”',
  '酒桌上废物女婿咳嗽一声，各国首富吓得集体下跪，岳母一家吓傻了',
  '穷女婿请吃饭，丈母娘故意点贵菜羞辱，不料女婿一开口全家都懵了',
  '单身富婆看上穷小伙，结婚当日出10亿嫁妆，第二天醒来后大喜！',
  '穷小伙被众人轻视，谁知真实身份竟是神秘龙神，当场拿出千亿黑卡',
  '岳母寿宴，被羞辱的穷女婿一个电话叫首富送来百亿合同，众人惊呆',
  '废物女婿亮出惊人身份后，全家哭求女婿：好女婿，你别离开我女儿',
  '穷老公含泪离婚，100架豪车来接，前妻惊呆：你不是送快递的吗？',
  '休婿宴上，千亿集团董事长见到傻女婿，竟鞠躬叫少爷，众人傻眼',
  '窝囊女婿请吃饭，丈母娘故意只点烧鸡内涵女婿，女婿怒，结局反转',
  '闺蜜跪着求我看的短剧，天哪，快来看哥哥的逆天神颜，点击看后续',
  '闺蜜哭着求我看的短剧！天哪这个哥哥也太帅了吧！点击看后续！',
  '不装了！摊牌了！穷女婿竟是千亿富豪！超精彩短剧，点击看完整版',
  '熬夜都要看的赘婿短剧，全程高能，一口气看到大结局！',
  '让人尖叫不止的精彩短剧，不建议看第二遍，怕你停不下来',
  '150集已完结都市赘婿短剧，8万人评出9.8分，一直看到大结局！',
  '别再找了，这才是你想看的短剧，内容太精彩了，不看后悔~',
  '熬夜都要看的爽剧，全程高能开挂逆袭，一口气看到大结局!',
  '这部剧真的有毒，我爸妈炒菜吃饭都在看，到底有什么好看的',
  '天哪！这短剧也太让人上瘾了！特别是第6集',
  '穷小子一夜变首富，看他如何对付曾经欺负过他的人，真的很过瘾',
  '丈母娘寿宴，他含泪签下离婚协议，转头走向路边停了3年的豪车！',
  '看了第一集，想看第二集，看了第二集，想看第三集的短剧…',
  '爆火短剧，希望你不会遇见这样的人！',
  '2023年公认爆火的都市热门短剧，200集已完结，一直看到大结局',
  '这部短剧太火了！点击下方链接看全集，结局看得我太爽了...',
  '罗斯柴尔德家族对他为命是尊，华国第一战神，25岁华夏最年轻将星',
  '一部被低估的短剧，两个字评价：精彩！精彩！还是精彩！',
  '妈妈，我真的哭了！这家短剧也太好看了吧！关键前面还免费！',
  '继承人是一种什么体验？看完这个才知道的是，自己是什么身份',
  '失忆女婿被辱，无奈亮出身份，五大家族齐齐躬身：恭迎龙神归位！',
  '岳父嫌我无能赶我出门，哪知我身价千亿财富能掌控整个世界走势！',
  '妻子破产偷拿窝囊丈夫银行卡取钱，大厅经理：请问您要取多少亿？',
  '五年来隐藏百亿身价入赘，如今一纸离婚书，令他无奈重回巅峰',
  '女总裁突然怀孕，瞪了保安一眼，“老公我怀孕了”公司的人惊呆了',
  '给我查！是谁撤走了公司8000亿股份！小姐，是你刚离婚废物老公',
  '“少爷，夫人电话”，他慌张放下酒杯，狂奔回家，完了门禁！',
  '爆款逆袭爽文小说，叶家少爷归来，看他如何纵横都市护娇妻！',
  '从小受尽冷眼羞辱，如今成为帝国首富，真实身份曝光，打脸众人',
  '老公请吃饭，遭同学刁难，媳妇无奈爆出神秘身份，众人吓懵了！',
  '丈母娘拐着弯嫌弃女婿，谁知女婿曝光神医身份，丈母娘表情绝了！',
  '一代龙神归来，看他如何逆袭！纵横都市，横扫各大势力！',
  '傻子老公入赘5年，被妻子拉去宴会，各国首付吓得直冒冷汗！',
  '各国首富见到女婿，吓得酒杯都拿不稳…岳母都惊呆了',
  '家族聚会，大家提议AA制，穷女婿笑了笑说：这家酒店是我的',
  '保安嘲讽穷外卖员却被经理掌掴，你知道她是谁吗？',
  '妻子生病没钱治，穷女婿不装了，一个电话叫来百辆豪车，惊呆众人',
  '假戏真做，谁知这个传闻中不近女色的帝国总裁，却宠她入骨',
  '丈母娘打算卖掉女婿的车，买家出价99亿，见到车牌直接吓懵了!',
  '隐瞒自己888亿的身家财产，三年来忍辱负重，今天他摊牌了…',
  '布衣青年受辱，十万黑衣战将席卷而来，诺大繁华都市一夜倾覆',
  '第一美女竟自愿嫁给穷女婿，谁知他竟权势滔天岳母直呼：好女婿！',
  '废物上门女婿，以狂婿战神之姿，横扫四境八荒，王者归来',
  '婆婆瞧不起穷儿媳，一年后儿子破产，穷媳妇竟被豪车接走！',
  '千亿集团董事长见到穷女婿，竟低头鞠躬叫龙王殿下',
  '6年前，穷小伙被设计陷害入狱，6年后，王者归来，如何逆袭？',
  '给妻子洗脚3年的上门龙婿，宴会上亮出真实身份，妻子瞬间吓瘫！',
  '十万人打出9.5爆款高分短剧，有工作的人一定要看',
  '岳母:"做我女婿,你也配?"穷小子默默起身拿出令牌,岳母吓瘫了',
  '被前女友抛弃，穷小子觉醒一亿神豪系统，买买买走上人生巅峰！',
  '岳母处处挤兑穷女婿，谁知穷女婿深藏不露，亮出身份，震惊岳母！',
  '岳母寿宴，穷女婿被当众赶下餐桌，酒店老总看到竟然大怒',
  '曾经的王者，如今的战神归来，竟然是龙王殿的创始人！',
  '南城叶家大小姐，竟然嫁给了一个乞丐，我的天！家族众人震惊！',
  '“给我查，我那个窝囊老公什么来头”“小姐，他…我不敢说”',
  '为爱上门却受尽冷眼，窝囊女婿真实身份曝光，打脸众人！',
  '穷司机被众人看不起，如今亮出真实身份，震惊众人！',
  '老婆欠下8千万，为了还钱去搬砖，赘婿出现，债主竟跪喊：少爷！',
  '他们都叫我窝囊废女婿，殊不知我是亿万富翁...',
  '求求了！导演会拍就多拍点吧,这短剧也太绝了吧',
  '妻子破产，偷拿窝囊老公的银行卡取钱，行长：您取多少亿？',
  '逆袭爽文，废物女婿竟是威慑天下的叶家少爷',
  '堂堂神帅竟甘愿入赘为婿，婚礼当天，千亿董事长跪地:拜见战神!',
  '一代龙神归来，看他如何纵横都市，横扫各大势力！',
  '同学聚会，夫妻二人惨遭羞辱，下一秒，8000保镖到场！',
  '离婚当天，他拨通了那个八年前号码，千名美女保镖来接，妻子傻眼',
  '先婚后爱，他入赘三年，终于俘获美女芳心，成就人生巅峰！',
  '你没看错！不用下载的短剧，直接免费看',
  '“动他一根汗毛，我灭你满门”7个天王回国第一天，灭了4大家族',
  '“老公我怀孕了”小保安慌忙关掉免提，众人大惊，是总裁的声音！',
  '丈母娘嫌弃穷女婿，让女婿签离婚协议，女婿签完开飞机走了',
  '被百般刁难，忍了三年的他显露身份，下一秒丈母娘求原谅',
  '门外8000人齐喊：请虎帅出山！岳母：谁是虎帅？女婿默默起身',
  '岳父寿宴，大女婿二女婿都开豪车，穷女婿打来电话：我飞机停哪？',
  '一代龙王归来，看他如何逆袭！纵横都市，霸气护娇妻！',
  '给我查，救活小姐的那个神医是谁！老爷，是那个啃馒头的穷小子',
  '穷女婿进入豪门遭亲戚冷眼，女婿身份曝光后，岳母当众跪求原谅',
  '三天前，他是路边乞丐，三天后，他摇身一变竟成江城商业帝国之子',
  '“老爷，战神离婚了”，“什么！快让小姐带100亿去求婚！”',
  '二婚三年，丈母娘从不待见送快递的他，直到看到他的工资条，吓瘫',
  '千亿女总裁抢救无效宣布死亡,被穷小子三针救活!在场88位神医痛哭',
  '火遍全网的热门爽剧！点击链接直接观看完整剧集！',
  '女儿被辱，穷女婿一个电话叫来8000保镖，岳母一家直接吓哭',
  '小伙儿意外得到龙王传承，回归都市，逆袭人生！',
  '前女友嘲讽他坐不起奔驰，他不屑一笑，扭头上了保时捷扬长而去！',
  '替嫁娇妻险被辱，总裁突然站起来，怒吼：我的女人你也敢碰？',
  '退役兵王回归都市，却被其他人瞧不起，不料他竟是龙王殿创始人',
  '妻子带废物老公赴宴遇到首富，老公：竟是你小子，怎么还这么穷？',
  '短剧都开始内卷了，不看到二十六集真的会后悔，太精彩了这也！',
  '岳母看不起穷小子，订婚宴当天，丈夫用500辆卡车拉来100亿现金',
  '富二代后悔了，没想到随便欺负的小伙竟是......',
  '赘婿！没我王家，我看你怎么活！我豪门继承人，何须人看！',
  '她嫁给了一个傻子，谁知傻子竟然是装的，真实身份惊呆全城！',
  '穷女婿常年遭受辱骂，掏出黑卡受众人膜拜',
  '家族聚会，50w的一桌、500w的一桌，神婿：5000亿的坐哪？',
  '岳母一家坐飞机，错拿穷女婿证件，航班吓得延误8小时!',
  '穷女婿上门吃饭，满桌的大鱼大肉，女婿却只能啃馒头',
  '穷女婿含泪和妻子离婚，隔天妻子上班见到新任总裁直接傻眼了',
  '老太爷寿宴，300w的一桌、100w的一桌，神婿：1000亿',
  '穷女婿不断隐忍，摇身一变成为千亿集团总裁，众人惊呆了！',
  '穷小伙夫妇当场被人羞辱，却不知小伙竟是神秘龙神',
  '妻子拿着穷老公给的木牌去首富家，首富竟弯腰喊：夫人！',
  '三年赘婿，他受尽冷嘲热讽，吃口菜都要看脸色，无奈下暴露身份…',
  '遭妻子背叛,被岳母嫌弃,如今回归，看他如何逆袭成为首富',
  '岳母偷卖女婿的车，买家出价一千万，见到车牌，竟然当场吓瘫',
  '穷小伙真实身份竟然是龙王，坐拥千亿资产',
  '穷女婿签完离婚协议，管家派一万仆人列队迎接，岳母惊呆',
  '当了三年穷女孩，忽然有一天，母亲告诉她：你有一家公司市值千亿',
  '穷女婿被丈母娘各种轻视，谁知女婿竟是首富继承人！',
  '入赘三年，傻女婿吃块肉都要看脸色，他无奈暴露身份',
  '穷小子被侮辱，被嘲笑，没想到竟是隐藏身份的隐藏豪门！',
  '妻子欠债5千万，背猪还债，上门女婿带钱还债，债主竟当场吓瘫！',
  '妻子被下套，欠下5亿，穷女婿竟说：我一个电话搞定，妻子惊了！',
  '“给我查，救活老爷的那个神医是谁”小姐，就是门口站岗的穷保安',
  '老公三年没出门，被妻子拉去宴会，全世界首富吓得瑟瑟发抖',
  '这个恶毒的女人，打骂羞辱穷老公，谁知小子一朝翻身，她后悔了！',
  '女总裁：保安呢？经理：洗厕所。女总裁：发文各部门，他是我老公',
  '有工作的人千万要看，尤其是第三集，让人越看越上瘾',
  '岳母过寿穷女婿含泪签下离婚协议，转头走向车库停了三年的豪车',
  '意外闪婚，老公居然是富可敌国的亿万总裁，婚后宠她入骨!',
  '爆款逆袭爽剧，叶家少爷归来，看他如何纵横都市护娇妻！',
  '入赘女婿被迫离婚，签完协议书！当场私人飞机接走，妻子一家傻眼',
  '门外800人齐喊：请总裁出山！丈母娘：谁是总裁？穷女婿竟站起身',
  '震惊快递界!集团女总裁死亡通知单刚下,就被一个落魄快递员救活了',
  '小姨子带男朋友回家，却不知姐夫竟是超级富二代！',
  '顶级富豪聚会现场，废物女婿亮出自己真实身份，各路大佬纷纷跪拜',
  '上门女婿送妻子鲜花反被辱骂，亮出身份后妻子悔不当初',
  '逆袭爽文，废物小伙竟是威慑天下的集团首富继承人',
  '【惊呆】快递员老公竟是千亿继承人，岳母惊呆：好女婿别走',
  '岳父趁女婿睡觉翻他银行卡，袜子竟掉出神秘证件，岳父一看傻眼了',
  '逆袭爽文，废物女婿竟是赫赫战功威慑天下的龙王',
  '家族聚会，开宝马一桌，开奔驰一桌，穷女婿却问开飞机的坐哪？',
  '妻子让无能女婿给老板敬酒，结果老板颤抖着说：少…少爷…您来了',
  '女总裁一夜破产为还债带废材保镖参加宴会，结果债主吓得吞了借条',
  '岳父寿宴，穷女婿被安排与狗一桌，妻子：“和狗一桌他也配？”',
  '上门女婿竟然是隐藏实力的豪门继承人，不服不行，他竟是...',
  '独身多年的战神终于娶媳妇了，老爷子下令：给我往死里宠！',
  '穷小子进城来退婚，却被京城第一美女总裁捉住：“叫媳妇儿!”',
  '打脸！窝囊女婿签完离婚协议竟坐豪车潇洒离去！美艳娇妻后悔了！',
  '妻子一夜破产，坐穷老公的三轮车去赴宴，谁知债主竟吓得吞下欠条',
  '兄弟被辱，弟媳一个电话叫来8000保镖，富二代小弟们直接吓哭！',
  '史上最强看守，一座铁狱关押全球四大恶人，无一人敢逃',
  '掌握各城经济命脉的超级大佬，竟是一个十八岁的毛头小子？',
  '被亲戚嫌弃的落魄穷女婿，真实身份竟是顶级富豪！',
  '富二代们后悔了，曾经欺负到大的乞丐竟是是...',
  '父亲为其千金配偶，怎耐看上路边乞丐，一耳光将女儿打倒在地...',
  '男子想用金钱俘获美女的心，不料对方一个手势直接让他破产！',
  '天天被岳母羞辱的废物女婿，谁知竟是龙神！岳母哭晕：我错了',
  '穷女婿请吃饭，丈母娘故意点满贵菜，女婿一开口，全场吓懵了！',
  '他是人人鄙视的上门女婿，却没人知道他的真实身份竟是..',
  '上门女婿含泪签下离婚协议，门口百名私人保镖来接，岳母惊呆了！',
  '女婿离婚后被千亿女董事长接走，妻子吓傻：你不是保安吗？',
  '穷女婿进入豪门遭亲戚冷眼，一朝恢复身份，身价千亿惊呆所有人！',
  '上门女婿，被岳母嫌弃，亮出身份后，众人惊呆了！',
  '穷女婿进入豪门遭亲戚冷眼，女婿身份曝光后，岳母当众哭求原谅',
  '上门女婿被丈母娘骂窝囊废？殊不知他是令人闻风丧胆的豪门少爷！',
  '重生醒来她扑进他怀里“王爷”，他压抑眼底的喜悦：乖，再喊一次',
  '上门女婿的真实身份，如今为了妻子亮出身份马上就惊呆众人~',
  '穷小伙被前妻当众羞辱，谁知他一个电话，3秒后前妻家族全破产！',
  '穷赘婿刚离婚，一群保镖进门高呼，恭迎龙主回归!',
  '丈母娘寿宴，女婿被亲戚嘲笑，妻子：门口豪车谁的，女婿：我的！',
  '这里看剧真上头，无广告，无弹窗，热门好剧通通在线看！',
  '他是帝国第一大家族的掌权人，手眼通天冷酷狠厉，却把她宠上天..',
  '总裁办公室传来一声惨叫，全集团都知道，龙王又被小娇妻狂揍了',
  '家里设宴，穷女婿被安排与狗一桌，妻子：和狗一桌，你也配？',
  '他受人排挤，却阴差阳错进入豪门，成了一名上门女婿，受尽凌辱',
  '他消失整整七年，所有人以为他早已去世，没想到归来却是“龙王”',
  '上门女婿被丈母娘骂窝囊废？殊不知女婿竟是集团首富继承人！',
  '老公装穷试探，遭妻子非人羞辱，一个电话所有人后悔了',
  '集团董事长见到窝囊女婿，竟低头叫少爷！岳母惊呆了！',
  '“少爷，三年之期已到，不用再隐忍了！”“是时候了！”',
  '上门老公摇身一变成了最强赘婿，他竟是…',
  '超过瘾的短视频，看了还想看，熬夜也想看完，精彩大结局',
  '穷女婿请吃饭，丈母娘故意点贵菜羞辱谁知女婿一句话，全场吓傻',
  '做我女婿，你不配！下秒，穷女婿一电话，百架飞机送来百亿现金！',
  '在外铁血手段，对着老婆毫无底线，冰山总裁是如何宠妻的？',
  '逆袭爽剧，穷小伙竟是集团首富继承人，看他如何霸气反击！',
  '上门女婿一夜翻身成富豪，手持至尊黑卡！岳母大喊：好女婿别离婚',
  '债主找上门，岳母把“废物”女婿拉来抵债，债主们看到他吓得颤抖',
  '婆婆寿宴，穷儿媳被安排与狗一桌，老公：“和狗一桌她也配？”',
  '三分钟就让人上瘾的剧情，剧情紧凑，太好看了！',
  '集团少爷流落江湖，五年打服无数豪绅枭雄，只为找到当年救命恩人',
  '丈母娘为贬低女婿点贵菜让穷女婿买单，不料女婿一开口便惊呆众人',
  '婆婆偷卖儿媳的车，买家出价一千万，见到车牌，竟然当场吓瘫',
  '30岁以上的人千万要看，这部短剧内容太精彩，熬夜都要看完……',
  '家宴上，岳母当众羞辱女婿，谁知女婿甩出一张破纸条，岳母吓哭！',
  '上门女婿含泪离婚，千名美女来接，妻子吓傻：你不是工地搬砖的吗',
  '岳母寿宴，开宝马一桌，开奔驰一桌，穷女婿却问开飞机的坐哪？',
  '总裁爱吃海鲜，吃完后一觉起来变成闾左无赖！',
  '上门龙婿被迫离婚后开启逆袭人生剧本，妻子一家人直接傻眼',
  '悄悄告诉你！我昨晚看了这部短剧，一次性看到天亮',
  '一定要看第9集，真的以牙还牙，爽翻天!',
  '他低调回国,只带3人,一人权势滔天,一人富可敌国，一个管凡间生死',
  '“给我查！我女婿到底什么身份？”看着桌面上女婿的证件，惊呆了',
  '24岁以上的人千万要看，特别是前三集，让人越看越上瘾',
  '200集已完结都市热门短剧，8万人评出9.8分，一直看到大结局',
  '奶奶寿宴上无能女婿咳嗽一声，全国首富集体起立，岳母一家吓傻了',
  '女婿含泪离婚，签完字，各国首富跪地喊主人！全家人直接傻眼！',
  '穷女婿入赘三年，谁知他竟是绝世龙神，离婚那天800美女保镖来接',
  '一代龙王归来，一声令下号召百人！纵横都市，横扫各大势力！',
  '有工作的人千万要看啊，尤其是看第三集，让人越看越上瘾！',
  '爆款逆袭短剧，穷小伙真实身份竟是首富继承人，高能反转',
  '“给我查，我那个乡下老婆什么来头”“她…我不敢说！”',
  '穷女婿被安排在角落，没想到各行大佬最先给他敬酒，岳母一家惊呆',
  '王者归来，美人在怀，就算是复杂的都市他也能化险为夷',
  '小保安入职第一天，偶遇美女总裁转身就跑，女总裁黑脸：叫老婆！',
  '他的名字是整座城的禁忌，听说他回来了，十大首富激动落泪：老大',
  '有工作的人千万要看，尤其是看第三集，让人越看越上瘾',
  '隐瞒自己888亿的身家财产，三年来忍辱负重，今天他摊牌了...',
  '穷女婿请吃饭,岳父故意点贵菜欺辱,怎料服务员一开口全家人懵了',
  '穷女婿请吃饭，丈母娘故意点贵菜不料女婿一句话，全场亲戚吓傻！',
  '这部剧有毒，蹲厕所看了半小时，腿都麻了，太精彩了',
  '上门女婿被迫离婚，签完字，各国首富跪地喊主人！妻子惊呆了',
  '穷保安买单亮出黑卡，众人当场大吃一惊！原来他真实身份竟是..',
  '吴家的上门女婿竟是叶家大少，丈母娘得知后，当场吓傻！',
  '一块玉佩引发的身世故事，首富继承人竟然是一个工地搬砖穷小子',
  '保安小伙被当众羞辱，却不知小伙真实身份却是首富',
  '养父瞧不起养子，却不知养子才是真正的大佬！',
  '“给我查！是谁收购了公司！”“是您刚刚赶走的搬砖女婿…”',
  '穷小伙一个善举，竞被富婆慕家千金看上，自此翻身权势无双',
  '傻女婿含泪和妻子离婚，隔天妻子上班见到新任总裁直接傻眼了',
  '一代龙皇归来，一声令下号召百人！纵横都市，横扫各大势力！',
  '直到妻子被欺负，装傻狂婿楚尘终于露出了真实身份，全城炸',
  '穷小子居然是集团继承人，曾经欺负过他的人都震惊了',
  '他是令人闻风丧胆的大人物，却甘愿为了心中所爱隐居都市',
  '一代龙王归来，看他如何逆袭！纵横都市，横扫各大势力！',
  '第一天相亲，第二天领证，第三天见到新总裁她傻眼了……',
  '“边关告急，恭请殿下出山”，正在给老婆系鞋带的女婿默默起身',
  '集团董事长见到穷小伙，竟鞠躬叫少爷！妻子震惊：你真的是神豪？',
  '超凡医圣重返都市却遭退婚，三天后打开电视，岳父母惊呆了',
  'N刷不腻的虐恋宠剧，点击看精彩剧情',
  '上门女婿含泪和妻子离婚，第二天妻子上班见到新任总裁直接傻眼了',
  '农民工女婿包下最贵酒店举办家宴，岳母留下悔恨泪水',
  '他从工地干活回来，本想夹块排骨，被岳母喂了狗，终于亮出了身份',
  '"三年之期已到,恭迎战王归位",岳母:他要是战王我就是神仙呢',
  '穷女婿被逼含泪和妻子离婚，第二天妻子上班见到新任总裁傻眼了',
  '妻子被辱，穷小子意外重生，归来继承千亿家.产开启复仇！恶人慌了',
  '女婿靠搬砖为生，直到岳母意外捡到女婿工资条，看清工资后傻眼！',
  '岳母瞧不起穷女婿，一年后妻子破产，穷女婿竟被豪车接走！',
  '再次重申！看剧太上头别来打我，不说了我要去看第五遍了',
  '战神归来，上百小弟前去机场迎接，然而家中却发生了变故…',
  '妈耶！慎入！！！短剧刷的真的停不下来',
  '上门女婿被各种轻视，谁知女婿的身份竟然…',
  '婆婆看不起穷女婿，不料女婿爆出真实身份后连老公公都吓傻了',
  '“5亿请神医救我儿一命”院长：什么神医？小保安默默站出来！',
  '老太错拿无用女婿的卡取钱，惊动了银行总部，银行董事亲自接待',
  '打脸！窝囊女婿签完离婚协议竟坐豪车潇洒离去！美艳娇妻后悔了',
  '逆袭爽剧，穷小伙竟是豪门殿下，纵横都市，横扫各大势力！',
  '穷女婿遇到大麻烦，一通电话竟招来一队保镖，众人吓腿软',
  '废物女婿入赘三年遭受欺辱，亮出真实身份惊呆众人',
  '“查！我装傻5年的老公，到底是谁”“小姐，他的档案sss级绝密”',
  '小保安发工资请吃饭，女总裁走来：记得给孩子买奶粉！全公司炸了',
  '穷女婿请吃饭，岳母故意点贵菜，怎料服务员一开口全家人懵了！',
  '老婆深陷危机，废物老公及时赶到，一拳打倒众人，他竟是集团少爷',
  '岳父寿宴，穷女婿被当众赶下餐桌，酒店老总看到竟大怒“撤菜！”',
  '窝囊废般的上门女婿，没想到身后势力这么神秘，总裁对他毕恭毕敬',
  'AA制同学聚会误点名酒消费1000万，穷小伙大喊：这顿我请',
  '赫赫有名的修罗殿下，为了儿时帮他的女人甘愿入赘做个窝囊女婿！',
  '龙哥是女主的青梅竹马？孩子都快生了，老公却被堵了？',
  '女婿请吃高级饭局，丈母娘点贵菜羞辱，谁知女婿开口，全家都懵了',
  '相亲当天，豪门继承人拉着我领证',
  '第一美女竟自愿嫁给穷女婿，谁知他竟权势滔天，岳母直呼：好女婿',
  '“请龙神出山！”看着五大家族跪求的穷女婿，岳母惊呆了',
  '穷女婿请吃饭，被故意点贵菜羞辱，女婿一开口，一桌人吓得腿发抖',
  '上门女婿含泪离婚，第二天妻子公司被收购，岳母见到新老板傻眼了',
  '集团总裁见到穷小伙竟当场下跪参拜，总裁：少爷好',
  '第一天入职遇到总裁，她低头走，总裁黑脸：老公都不叫！',
  '妻子破产负债千亿，带着穷老公去还债，首富们见到竟吓得瑟瑟发抖',
  '左手绝世武功，右手生死骨肉，穷小子开启邪医血脉，无敌都市！',
  '太太过寿，大儿子开大奔二儿子开宝马，穷女婿问：我飞机停哪？',
  '丈母娘骂我窝囊废，说我吃软饭…然而他们都不知道我的真实身份',
  '拜金妻子逼穷小伙离婚，谁知他竟是千亿继承人！',
  '乌鸡也想变凤凰？千亿美金，买你柳家绰绰有余！婚约即刻解除！',
  '妻子聚会被年薪百万的班长羞辱，废物丈夫突然开口：你被开除了！',
  '土豪女婿被岳父看不起，软的不行来硬的，不料被一拳干倒',
  '为了公司上市被亲人陷害，出狱后再次证明自己',
  '一代战神回归却发现妻子和妹妹被辱，这次，他要手刃仇人！',
  '穷女婿请吃饭，岳父故意点贵菜欺辱，怎料服务员一开口全家人懵了',
  '穷女婿受尽各种折磨，各方老板纷纷上门，岳母崩溃了：凭什么是他',
  '穷女婿3年受尽丈母娘羞辱，晚宴上全城富豪给他敬酒，岳母傻眼',
  '妻子带窝囊老公参加神医聚会，一进门，全场神医高呼：医神！',
  '一场替嫁，她嫁给了传闻中坐轮椅的男人，谁知大婚当天....',
  '老婆被人欺负，穷女婿忍无可忍爆出神秘身份，怒甩黑金卡！',
  '他将女儿抚养长大，却被告上法庭，然而真相曝光后，女儿哭晕了',
  '穷女婿被保安认成农民工，拿出黑卡后酒店经理亲自迎接',
  '搬砖女婿摇身一变成千亿集团总裁，岳母大惊：“好女婿，不离婚”',
  '穷女婿被辱，无奈亮出身份，五大家族齐齐躬身：恭迎龙神归位！',
  '做了3年穷女婿，夹块排骨，被丈母娘打掉，喂狗！女婿终于怒了',
  '穷小伙当场被羞辱，却不知小伙竟是豪门少爷，结局大快人心',
  '老丈人寿宴，开豪车一桌，开超跑一桌，穷女婿却问：开飞机的坐哪',
  '天王阁殿下归来，发现妻子受尽冷眼，势必让他们付出代价！',
  '穷小伙夫妇当场被人羞辱，却不知小伙竟是神秘龙皇殿下',
  '被隐瞒的25身世终揭晓，千亿家产继承人该易主了',
  '“请龙神出山！”看着五大家族跪求的穷女婿，妻子惊呆了',
  '“什么？他竟然是是京城叶家独子？”岳母得知穷女婿身份慌了！',
  '“这废物怎有至尊血脉？”“大小姐，他继承了龙皇意志”',
  '一代龙王归来，看他如何纵横都市，霸气护娇妻！',
  '女儿求救，蓝海囚牢三千强者，九大战神，四大天王从天而降！',
  '窝囊上门女婿，被岳母一家嘲讽打骂，亮出真实身份后众人惊呆了！',
  '穷小伙全家被灭，六年后王者归来，终报大仇！',
  '窝囊老公出席宴会，一进场全体起立，妻子懵了：你不是保安吗',
  '穷女婿遭岳母被看不起，如今亮出真实身份，震惊众人！',
  '小保安入职表填未婚，下班被女总裁堵：你不识字还是我拿不出手？',
  '一代龙皇归来，看他如何纵横都市，霸气护娇妻！',
  '穷女婿受尽丈母娘欺辱，当晚宴会上全城富豪给他敬酒，岳母后悔？',
  '总裁吃了一顿海鲜盛宴，一觉起来成了穷光蛋，离谱！',
  '“给我查，我那个窝囊老公什么来头”“小姐，他…我不敢说！”',
  '岳父寿宴，穷女婿被当众赶下餐桌，酒店老总看到大怒“撤菜！”',
  '丈母娘寿宴，穷女婿走路来被亲戚嘲笑，保安：谁把飞机停门口？',
  '“敢动他一分，我灭你满门”龙王回国第一天，灭了3大家族',
  '穷小子被妻子羞辱打骂，谁知一朝翻身，前妻哭了：好老公别走',
  '外卖员不断隐忍，摇身一变成为千亿集团总裁，众人惊呆了！',
  '老爷子寿宴上，上门女婿不再隐忍，替妻子送出千万贺礼',
  '女婿惨遭岳母嫌弃：“你不配叫我妈！”谁知他神医身份让岳母傻眼',
  '穷女婿结婚三年天天被欺负，谁知千亿首富见他竟鞠躬叫龙神！',
  '岳母寿宴：大女婿送千年人参，二女婿送百万玉佩，穷女婿：就这？',
  '上门女婿被岳母嫌弃，亮出真实身份后，岳母惊呆了！',
  '你走，你这个窝囊废下一秒，妻子刷到废婿朋友圈，后悔莫及',
  '穷女婿参加宴会被拒，拿出黑卡后众人列队欢迎',
  '给我查，救活二小姐的神医是谁！夫人，是您家那个废物大女婿！',
  '董事长受尽非人羞辱，只因他是穷女婿，身份亮出，众人吓坏了！',
  '婆婆从不待见儿媳，离婚后看到她落在抽屉里的欠条，她终于...',
  '家族招待宴，窝囊女婿一进门，各桌大佬竟然吓得酒杯都端不稳。',
  '医院已宣布死亡，穷小子扎几根银针，千亿总裁女儿竟起死回生',
  '妻子被辱，穷女婿一个电话叫来8000保镖，岳母一家直接吓哭！',
  '真正的免费短剧，从第一集看到最后一集全免费！真好看，更新快',
  '当了三年赘婿，忽然有一天，母亲告诉他：你有一家公司市值千亿！',
  '“给我查,谁收购了集团？”“夫人,是您昨天赶走的上门女婿！”',
  '逆袭爽文，废物女婿竟是赫赫战功威慑天下的龙皇殿下',
  '妻子破产偷翻穷老公银行卡却掉出来神秘证件，打开一看妻子吓呆了',
  '曾经赫赫有名的修罗王，沦为上门女婿，如今王者归来，全城轰动',
  '上门女婿遭人嫌弃，亮出身份后，竟是豪门继承人！',
  '窝囊女婿曾是第一帮会创始人，一手遮天，如今隐忍三年，强势逆袭',
  '“给我查，我丈夫到底是什么身份？”“小姐！他..不得了啊！”',
  '最终欠钱的人被牵制，被要求舔桌子，是怎么样做到的？',
  '三年前被冤枉入狱，今日终于回归自由。终要拿回属于自己的一切！',
  '穷小伙变千亿家产继承人，震惊拜金前妻，结局令人解恨',
  '项真龙得逞，骗得女神做自己的女秘书，这里面有什么秘密吗？',
  '岳父寿宴，穷女婿被当众赶下餐桌，酒店老总看到大怒：“撤菜！”',
  '热门女婿逆袭小说：宴会上，岳母彻底傻眼，原来高攀不起的是她！',
  '上门女婿含泪离婚，第二天妻子公司被收购，见到新老板傻眼了',
  '总裁三代单传，没想到她一胎三宝，老爷子放话：给我往死里宠！',
  '穷女婿说能解决家族五十亿债务被鄙夷！亮出身份后，全家傻眼！',
  '妻子被辱，窝囊老公一个电话叫来8000保安，岳母一家直接吓哭',
  '她闪婚战家，半年破了战家十代单传！婆婆笑翻全城公主裙断货',
  '女总裁破产，带穷保安去还债，债主见了竟当场吞下欠条！',
  '丈母娘带富二代准女婿逼离婚，窝囊赘婿当场怒砸百亿，丈母娘懵了',
  '终于找到这本小说了，一口气免费追到大结局，超过瘾！',
  '穷小伙当场被羞辱，却不知小伙竟是首富继承人，反转不断',
  '“当我老公，你不配！”他含泪签下离婚协议，妻子刷朋友圈傻眼了',
  '妻子偷卖女婿的车，买家出价一千万，见到车牌，竟然当场吓瘫',
  '老公含泪离婚，千名女保镖来接，妻子傻眼了：你不是工地搬砖吗',
  '老爷子过寿，大女婿二女婿开豪车，穷女婿问：我开飞机停哪',
  '穷女婿被各种轻视，谁知女婿竟有神秘身份！',
  '千亿集团董事长见到穷女婿，竟低头鞠躬叫少爷好',
  '千亿集团董事长见到穷女婿，竟低头鞠躬叫总裁，岳母：好女婿',
  '穷女婿请吃饭，丈母娘故意点贵菜刁难，女婿一开口，众人吓的发抖',
  '“给我查，我那个窝囊老公什么来头！”“小姐，他我不敢说！！”',
  '老板想请乞丐女婿做贴身保镖，谁知乞丐女婿当面拒绝，身价太高！',
  '“查，我装傻5年的老公，到底是谁”“小姐，他档案ssss绝密”',
  '老公含泪离婚，千名女保镖来接，妻子傻眼了：你不是工地搬砖吗？',
  '24岁以上的人千万要看，特别是前三集，让人越看越上瘾！',
  '穷女婿含泪签下离婚协议，拿起电话：三年之期已到，我回来了',
  '各国首富见到窝囊女婿，竞恭敬称呼主人，岳父岳母都惊呆了...',
  '“给我查！我那个保安老公究竟什么来头”“小姐，他…身份惊人”',
  '他归来后，看见妻子遭受欺辱，当场怒了，一条短信号召百人',
  '女婿赴宴，岳母故意点贵菜羞辱！谁知女婿直接甩出百亿黑卡！',
  '上门女婿消失五年归来，竟然成为龙王殿下#王者归来#短剧推荐',
  '罗斯柴尔德家族对他为命是尊，华国第一战神，25岁大夏最年轻至尊',
  '岳父寿宴，穷女婿被当众赶下餐桌，酒店老总看到竟然大怒',
  '被丈母娘逐出家门的入赘女婿晕倒路旁，醒来后的他竟成为w城首富',
  '他手下有三大神秘队伍，一狼牙二猎鹰，最后一支名字没人敢提...',
  '丈母娘肠子都悔青了，嫌弃了3年的上门女婿，竟有这等身份！',
  '被百般羞辱的赘婿，秒变首富继承人开始报复，反转太爽了！',
  '乡下儿媳给老公洗了三年脚，亮出身份后，婆婆吓得瘫软在地',
  '上门女婿竟然是隐藏实力的豪门继承人，不服不行，他竟是…',
  '窝囊上门女婿，给妻子洗了十年年脚，亮出真实身份妻子吓傻了！',
  '五年来隐藏百亿身价入赘，如今一纸离婚书，令他无奈重回巅峰！',
  '妻子家宴让无能老公陪伴，一进门，一桌人吓得酒杯都拿不稳',
  '“总裁，门外有个乞丐，被我赶走了。”女总裁暴怒：那是我老公！',
  '废物女婿被迫离婚，亮出身份后岳母求女儿复婚',
  '丈母娘过寿，小姨仗着年薪几百万羞辱女婿，女婿：你被开除了',
  '穷女婿被岳母逼离婚，公司破产被收购，前妻抬头一看：怎么是你？',
  '逆袭爽剧，穷女婿竟是千亿豪门继承人，反转不断',
  '妻子半夜起身，无意看到送快递的老公掉地上的工资条，看完傻眼了',
  '各地大佬见到废物女婿，竟全部鞠躬，岳母吓晕',
  '他有四个手下，一个掌权，一个掌财，一个掌命，最后一个掌….',
  '美女总裁求龙神合作，谁知他竟是自己结婚三年的穷老公！',
  '岳父寿宴，穷女婿竟被安排与狗一桌！女儿大怒：你可知他是谁？',
  '家庭聚会富二代提倡AA制，一问店员价格傻眼了，穷女婿：刷我的！',
  '小伙带着九转还魂针再现世上，老医生居然要叫他师叔！震惊！',
  '穷女婿含泪离婚，第二天妻子公司被收购，见到新老板傻了！',
  '左手生死骨肉，右手绝世武功，穷小子开启神王血脉，纵横都市！',
  '“穷女婿要被赶出家门？”，得知丈夫神秘身份，妻子的态度转变了',
  '废女婿抱娃参加宴会，妻子一脸嫌弃，听到别人称呼他时，当场吓傻',
  '开局穷小子，一路逆袭，路边乞丐为何从此逆天改命！',
  '家族聚会花费百万，全家傻了！女婿一句话，全城富豪抢着买单',
  '上门女婿给卡让老婆去银行取钱，行长拿到卡后，全银行都傻眼了！',
  '兵王退役回归都市，回到都市，看他如何掀起腥风血雨！',
  '3任前夫死后，他嫁给了路边乞丐，不料新婚当天他竟然是龙王！',
  '逆袭爽剧，穷小伙竟是豪门少爷，叶家继承人，反转不断',
  '妻子公司破产，乡下女婿竟随手转入1000万！真实身份竟是……',
  '丈母娘偷卖女婿的车，买家出价一千万，见到车牌，竟然当场吓瘫！',
  '“家族告急，恭请少爷出山”，岳母：谁是少爷？女婿默默起身',
  '穷小伙被众人轻视，谁知真实身份竟是神秘龙皇，当场拿出千亿黑卡',
  '全程高能，穷小伙真实身份竟然是豪门继承人，坐拥千亿资产',
  '上门女婿被迫离婚，签完字，当场被私人飞机接走，全家人直接傻眼',
  '帝国总裁徐少闪婚死对头，没想到婚后却把她宠上了天！',
  '入赘三年的窝囊废，今日被妻子联手公子哥赶出豪门，他不装了',
  '赘婿寿宴被打耳光，首富到来跪拜“请少爷回家”，众人懵了',
  '人人都想入赘首富家，这穷小子倒好竟要主动和富家千金退婚！难道',
  '家宴上妻子向他人敬酒，穷女婿亮出大少爷身份惊呆众人',
  '岳父寿宴，乞丐女婿被安排与狗一桌，妻子：“和狗一桌他也配？”',
  '傻子女婿被辱，无奈亮出身份，五大家族齐齐躬身：恭迎龙神归位！',
  '开宝马一桌，开奔驰一桌，穷女婿却问开飞机的坐哪？',
  '他是蓝星地下皇帝，全世界都在等他苏醒，今天，他睁开双眼……',
  '穷小伙真实身份竟然是龙皇殿下，坐拥千亿资产',
  '这到底是什么剧啊，现在我老公睡觉看，吃饭看，上厕所也要看？',
  '穷小子被赶出家族，口袋突然掉出神秘纸条，老太爷一看吓呆了！',
  '窝囊女婿还没离婚，就开始给娇妻介绍新男友，女婿当场怒摔百亿！',
  '各国首富见到窝囊女婿，竟恭敬称呼主人，岳父岳母都惊呆了…',
  '上门女婿被各种轻视，谁知女婿竟然身价千亿！',
  '上门龙婿遭众人冷眼，如今为了妻子亮出身份，惊呆众人！',
  '董事长女儿抢救无效宣布死亡,被穷小子一针救活!在场88位神医痛哭',
  '战神归来，全球富豪见他后全部鞠躬，齐喊战神，战神!',
  '穷女婿被各种轻视，谁知女婿身份竟是神秘富豪，当场拿出千亿黑卡',
  '倒插门三年，全家百般数落，亮出真正身份，岳母瑟瑟发抖',
  '女婿含泪签下离婚协议，门外数百保镖来接，岳母惊呆',
  '“动她一根汗毛，我灭你满门”修罗战神回国第一天，灭了四大家族',
  '快来看，爆好看的竖屏短剧都在这里，绝对让你看过瘾！',
  '人人瞧不起的穷女婿竟然扛麻袋取钱还债？惊呆众人！',
  '岳母：穷酸相配不上我女儿；三天后，岳母：好女婿，求你不要走',
  '家族寿宴，女婿步行被嘲笑，保安：谁把飞机停门口？女婿默默起身',
  '爷爷寿宴上无能女婿咳嗽一声，全国首富集体起立，岳父一家吓傻了',
  '女总裁带着窝囊女婿参加宴会，老板们见到大喊：少爷！女总裁懵了',
  '他医术惊人，活死人肉白骨，他武力骇人，杀人于无形中',
  '妻子聚会被年薪百万的班长羞辱，废物丈夫突然开口：你被开除了',
  '一直被人瞧不起的她，真实身份竟是公司董事长！',
  '穷小伙变千亿家产继承人，震惊拜金前岳母，结局令人解恨',
  '他是受尽白眼的赘婿,可在背地里他却是权势滔天的霸主！',
  '给我查！是谁撤走了公司8000亿股份！小姐，是你刚离婚废物老公！',
  '“小乞丐也配参加宴会？”谁知他丢下至尊黑卡，酒店老板亲自迎接',
  '岳父寿宴，穷女婿被安排去猪圈吃，妻子：“他也配？”',
  '龙王一怒，京都变天，上门女婿终于不在隐忍，全家人都......',
  '这部剧看了一晚上，根本停不下来',
  '他15岁失踪参军，服役神秘部队，退役后档案居然是SSSS级机密！',
  '娇妻被辱，穷女婿一个电话叫来8000保镖，富二代直接下跪！',
  '上门女婿饭局被辱，忍无可忍，女婿：限制已解除，收购杨家！',
  '老公公司破产，妻子竟随手转入1000万！没想到她的真实身份竟是',
  '穷女婿请吃饭，被故意点贵菜羞辱，不料女婿一开口富豪吓破胆',
  '岳母看不起穷女婿，结果女婿真实身份曝光后，岳母吓傻了！',
  '三年来隐藏百亿身价入赘，如今一纸离婚书，令他无奈重回巅峰',
  '千亿集团董事长见到穷女婿，竟低头鞠躬叫龙皇殿下',
  '能欠钱的才是大爷？终于体会到借钱要经历什么了！家人们！',
  '搬砖小伙自称首富继承人被同学嘲讽，谁想豪车停他面前“少爷请”',
  '穷小伙被众人轻视，真实身份竟是首富继承人，当场拿出千亿黑卡',
  '集团总裁见到穷小伙竟当场下跪参拜，总裁：龙皇殿下大人',
  '老总欠钱不还，女人说我就愿意跟着你。',
  '看到深夜的短剧，根本停不下来，就这一集，我反复看了6次！',
  '逆袭爽剧，穷小伙竟是神秘龙王，纵横都市，横扫各大势力！',
  '快递员受欺负，第二天女总裁现身快递站：“老公，求你回家！”',
  '给妻子洗了三年脚的上门女婿，被离婚后亮出底牌，岳母当场吓瘫！',
  '“给我查！是谁收购了公司！”“是您刚刚赶走的乡下儿媳妇”',
  '11月爆火的短剧，你还没看过吗？',
  '岳母寿宴，穷女婿竟被当众羞辱，谁知首富见到大怒：谁改欺辱龙神',
  '废物女婿参加宴会，被豪门公子嘲讽，女婿：让你爸过来道歉！',
  '上午相亲下午领证，女孩不知老公多富有，吞吞吐吐要500零花钱',
  '被侮辱的上门女婿无可忍，撕毁婚书，众人：恭迎战神',
  '妻子一家人欺辱废物女婿，却不知他是京城大少爷',
  '搬砖女婿参加豪门宴会被岳母当众掌掴，谁知首富见他吓得腿软',
  '丈母娘寿宴，穷小伙被安排与狗一桌，老婆：“和狗一桌他也配？”',
  '千万要去看这部短剧，我已经两天没合眼了，内容太精彩了！',
  '小伙请吃饭，同学故意点贵菜，不料她一开口全桌吓得筷子掉了！',
  '“你有500万，我叫你爸爸！”面对妻子嘲讽，窝囊女婿怒甩出黑卡',
  '蹲个坑的功夫，让你迷上这部剧，熬夜也要看完！',
  '窝囊女婿入赘三年，饱受欺凌，亮出身份后，岳母惊呆了',
  '快递员被前妻当众掌掴，谁知她上班见到新任总裁，傻眼了！',
  '看了第一章，想看第二章，看了第二章，想看第三章的热门短剧…',
  '一代龙王归来，看他如何纵横都市，横扫各大势力！',
  '废物女婿参加宴会，岳母一脸嫌弃，听到别人称呼他时，当场吓傻',
  '老太太嫌弃傻女婿，逼着傻女婿签离婚协议，女婿签完开飞机走了',
  '妻子家宴，女婿不再隐忍将京城大少身份公布于众',
  '有工作的人千万要看，尤其是看第三集，让人越看越上瘾.',
  '穷小子被首富家寻回，原来他是小少爷？之前欺负过他的人都等着吧',
  '他是领导家唯一男保安，戏耍大夫人，霸占整个家族玩弄于股掌之间',
  '兵王归来，发现家人遭受欺负，一个电话8000小弟前来报到！',
  '妻子欠下50亿，穷女婿一通电话，百辆豪车送来100亿，债主吓傻了',
  '“边关告急，首领恭请战神出山”，岳母：谁是战神？女婿默默起身',
  '丈母娘：50万，离开我女儿。穷女婿却说：我出一个亿，明天办婚礼',
  '战神从监区带回三个守卫，看似弱不禁风，实际上是各界前十高手！',
  '“就你也配娶我女儿？”“老爷不好了！公司被你未来女婿收购了”',
  '岳母看不起穷女婿，订婚宴当天，保安用500辆卡车拉来100亿现金',
  '五分钟让人上瘾的短剧，剧情紧凑，太好看了！',
  '废物上门女婿被看不起，如今亮出真实身份，震惊众人！',
  '“敢动她一分，灭你满门”战神赘婿回城第一天就灭了四大家族',
  '看了还想看，熬夜也想看完，精彩大结局让你看到停不下来',
  '家族会议上，老婆受辱，穷小子亮出底牌，惊呆势力丈母娘。',
  '岳母寿宴，穷女婿终于不再隐瞒身份，京城最贵酒店随时安排',
  '赘婿受辱竟然惹来燕京第一家族震怒，他究竟是何方神圣？',
  '穷女婿求合作被羞辱，谁知总裁见他竟吓呆：少爷您来了？',
  '小伙陪富婆去应酬遭羞辱！千亿财阀继承人身份曝光！',
  '穷小伙被众人轻视，谁知真实身份竟是叶家少爷，当场拿出千亿黑卡',
  '娇妻遇难题，小伙怒甩300万解围，小伙真实身份竟是龙皇殿下',
  '集团继承人隐瞒身份体验生活，却屡遭羞辱！',
  '丈母娘半夜起床上厕所，发现睡沙发的女婿掉出一张红纸，当场懵了',
  '岳母处处挤兑穷女婿，谁知这穷女婿深藏不露，亮出身份，震惊岳母',
  '“谁来救救我女儿”她泣不成声，穷女婿路过，一针救活，她懵了..',
  '一定要看第6集，真的以牙还牙，爽翻天!',
  '五分钟让人上瘾的短剧，第二集进入正题，剧情紧凑精彩不断',
  '窝囊上门女婿受尽屈辱，离婚后摊牌亮出真实身份吓傻妻子！',
  '“当我老公，你也配？”1个月后妻子刷到他朋友圈，直接傻眼了',
  '5秒就上瘾，没有人能抗住这部剧，特别是第五集',
  '岳母寿宴，女婿拿张破红纸当贺礼，看清内容后，全场吓傻！',
  '酒桌上无用儿媳咳嗽一声，各国首富集体鞠躬喊主人，婆婆惊呆',
  '全程高能，服务员真实身份竟然是首富继承人，坐拥千亿资产',
  '二婚三年，丈母娘从不待见上门的他，直到看到他的工资条，吓瘫',
  '穷女婿撞车竟被羞辱，谁知千亿首富见他竟鞠躬叫龙神！',
  '穷小子为隐瞒身份成快递小哥，却被美女总裁捉住：“叫老婆！',
  '真的！我劝你还是别看了！这短剧真的...也太好看了吧',
  '他是令敌人闻风丧胆的战神，掌握着全球的权势，只为复仇！！',
  '新来总裁竟是昨天闪婚丈夫，她随众人喊总裁好，总裁轻笑:老婆好',
  '穷女婿遭受欺辱，谁知他竟是集团总裁，权势滔天',
  '丈母娘起夜上厕所，无意捡到睡沙发女婿掉地下的工资条，看完吓瘫',
  '穷老公入赘3年，被妻子拉去宴会，各国首富吓得冒汗，不敢出声',
  '穷女婿与人发生口角，管家带10车保镖前来支援',
  '终于发现了看剧的好地方，点击继续观看',
  '逆袭爽文，废物女婿竟是赫赫战功威慑天下的龙王殿下',
  '爆款逆袭爽文小说，一代龙皇归来，看他如何纵横都市护娇妻！',
  '逆袭爽文，废物女婿竟是赫赫战功威慑天下的战神',
  '全程高能反转短剧，打脸一波接着一波，力荐',
  '美女总裁欠下50亿！穷老公竟说：“放心，我一个电话就搞定。”',
  '穷女婿请吃饭,岳母故意点贵菜欺辱,怎料服务员一开口全家人懵了',
  '家宴上，窝囊女婿甩3000千万替老婆还债，丈母娘当场惊呆！！！',
  '被叫了三年的穷鬼，因为妻子的一句话，亮出身份震撼全城！',
  '动她一根汗毛，我灭你满门”，他回国第一天，灭了6大家族！',
  '窝囊上门女婿，给妻子洗了三年脚，亮出真实身份妻子吓傻了！',
  '穷小子进城，竟被京城第一美女总裁捉住：“叫老婆！”',
  '女外卖员遭受欺辱，谁知他竟是集团总裁，权势滔天',
  '“给我查，我那废物老公的身份！”“小姐，他，他是千亿总裁！”',
  '逆袭爽剧，女孩竟是豪门女王，豪门继承人，反转不断',
  '老丈人寿宴,妻子问:给我查,门口的车是谁的?穷老公开口:我的,咋了',
  '婚礼当天，他家族破碎锒铛入狱…这幕后黑手竟是他的小姨子',
  '一块玉佩竟藏着他身世的秘密，被众人看不起的他竟是最强战神',
  "老佛爷寿宴，穷女婿把40年''压菜石”当寿礼，专家:至少5个亿",
  '公司破产，老婆拿老公的玉佩抵债，债主吓得瑟瑟发抖：龙行玉佩！',
  '婆婆寿宴，穷儿媳被安排与狗一桌，公公：“和狗一桌他也配？”',
  '“我在北疆护你们，尔等却害我妻子”，十二战神听令：血洗重城！',
  '神秘医术的拥有者，掌管医术异能，却做了上门女婿？',
  '富家千金为何要选择这个穷小子？他的真实身份是？',
  '百亿集团董事长见到窝囊女婿，竟低头叫少爷！岳母惊呆了！',
  '“我在北境卖命，尔等欺我妻女”，战神听令：即刻寸草不留！',
  '这本赘婿神作，全程高能反转，打脸一波儿接着一波儿，力推！',
  '丈母娘打算卖掉女婿的车，买家出价88亿，见到车牌，当场吓瘫！',
  '王者归来发现家人被辱，一个电话封锁全城，吓呆众人！',
  '废柴上门女婿遭女方家人羞辱，没想到他的身份贵不可言！',
  '他低调回国，只带3位半神，一位玉皇，一位财神，最后阎王...',
  '重生觉醒，美女总裁竟要在今晚和他结婚？连婚房都布置好了！',
  '美女总裁身边的保镖竟然是个乞丐？而他的真实身份竟然是...',
  '他左手惊天医术，右手至强武功，匡扶救世，玩转都市！',
  '穷女婿失忆被岳母一家羞辱，谁知他竟是失踪的千亿继承人',
  '逆袭爽剧，穷小伙竟是豪门少爷，看他如何纵横都市！',
  '丈母娘点贵菜让穷女婿买单，不料女婿一开口便惊呆众人',
  '项真龙头戴丝袜，拿着板砖冲进办公室催还钱？闻所未闻。',
  '穷小伙真实身份竟然是首富继承人，坐拥千亿资产',
  '“大人，他回来了”龙帮炸了：”什么？快通知八大家族，快跑！”',
  '穷小子进城来退婚，却被京城第一美女总裁捉住：“叫媳妇儿!',
  '岳母听到女婿打电话，得知废物女婿真实身份，当场吓晕…',
  '岳母让窝囊女婿敬酒，结果全场颤抖着说：少…少爷…您来了',
  '废物女婿遭妻子背叛，亮出身份派管家从严处理',
  '“是谁买了那个800万的车？！”“是您老同学的那个乡下女婿…”',
  '上门女婿被人鄙视嘲讽，当场被私人助理给到黑卡，全家人直接傻眼',
  '丈夫为了报答妻子，偷偷替还天价债务，却不料暴露了龙门身份',
  '家族聚会，资产500万的一桌，100万的一桌，穷女婿问：100亿坐哪',
  '穷女婿被丈母娘嫌弃，受尽欺压，亮出真实身份后，丈母娘惊呆了',
  '他竟然是龙门？你们当初那么羞辱他，现在哭都来不及！',
  '这个小短剧真的可，好看贼好看，垂直入坑了！！',
  '上门女婿受辱，十万黑衣战将席卷而来，诺大繁华都市一夜倾覆',
  '一代龙神归来，一声令下号召百人！纵横都市，横扫各大势力！',
  '“老公，不想再受欺负了！”穷小子为了妻子暴露绝世富豪身份！',
  '妻子公司破产，穷女婿竟随手转入3000万！真实身份竟是？',
  '三年历练已到，小伙振臂一呼，三万黑衣人齐喊恭迎龙王回归！！',
  '离婚当天，他拨通了那个八年的号码，千名美女保镖来接，妻子傻眼',
  '离婚吧！你没钱没权，不配跟我在一起签完字妻子当场后悔',
  '遭遇亲人陷害，出狱时决定再次证明',
  '穷女婿失忆被岳母一家羞辱，谁知他竟是失踪的千亿继承人！',
  '穷小子初次下山被退婚，谁知他有三重身份，战神，神医，最后…',
  '已完结都市热门短剧，8万人评出9.8分，一直看到大结局',
  '丈母娘宁可喂狗排骨也不愿女婿吃一口，不料女婿竟是战神吓傻岳母',
  '穷女婿被丈母娘各种轻视，谁知女婿竟有神秘身份！',
  '千亿集团董事长见到穷女婿，竟低头鞠躬叫龙神',
  '铁狱关押最强战力，那少年一人看守，却无一人敢逃！',
  '妻子得罪领导遭羞辱，穷小伙当场买下整栋大楼，领导当场吓瘫',
  '被欺辱三年，他终于亮明身份，家族震惊，老祖母当场昏倒！',
  '婆婆寿宴，穷儿媳走路来被亲戚嘲笑，保安：谁把飞机停门口？',
  '出门遇贵人，少年居然得到神医传授的万年医术，从此走上人生巅峰',
  '同学聚会被众人侮辱，穷女婿忍无可忍终于亮明身份……',
  '短篇虐文短剧，半小时看完，仅20章，强烈推荐！',
  '岳母为家宴犯愁，废物女婿直接包下最贵酒店，岳母傻眼',
  '妻子拿着老公给的木牌去帝国大厦，董事长竟弯腰喊：夫人！',
  '岳母瞧不起女婿，却不知自己巴结的人给女婿擦鞋都不配',
  '老公公司破产，保姆妻子一声令下，亿万资金到位，众人傻眼！',
  '穷小伙被众人轻视，谁知真实身份竟是神秘龙王，当场拿出千亿黑卡',
  '集团总裁见到穷小伙竟当场下跪参拜，总裁：首富好',
  '窝囊女婿被岳母要求离婚，女婿签完字开飞机离开，岳母傻眼！',
  '上门女婿一夜翻身成富豪，手持至尊黑卡！急忙大喊：好女婿别离婚',
  '奶奶寿宴，礼金50W坐一桌，70W坐一桌，废物女婿问：20亿的坐哪？',
  '穷小子隐瞒身份遭众人嫌弃，摊牌亮出真实身份，全场震惊！',
  '5秒就上瘾，没有人能抗住这部剧，特别是第7集',
  '穷小伙遭众人瞧不起，却不知小伙是首富继承人，高能反转！',
  '婆婆过寿，小姨仗着年薪几百万羞辱儿媳，儿媳：你被开除了',
  '岳母寿宴，女婿被当众赶下餐桌，忍无可忍他发出命令“撤菜！”',
  '女外卖员遭婆婆被看不起，如今亮出真实身份，震惊众人！',
  '岳母带女婿参加宴会，女婿不在隐忍，霸气逆袭',
  '岳父寿宴，穷女婿被安排与狗一桌，妻子：和狗一桌他也配？',
  '乡下儿媳请吃饭，婆婆故意点贵菜，不料儿媳一开口，一桌当场吓傻',
  '第一美女竟自愿嫁给穷女婿，谁知他竟权势滔天，龙王归来',
  '废物女婿亮出黑卡余额后，岳母跪求女婿，好女婿，你别离开我女儿',
  '酒店里穷女婿被羞辱，首富路过大怒：你敢动她试试！',
  '美女总裁断气24小时，被穷小子一针救活！在场88位神医痛哭',
  '落魄小伙意外穿越成废柴少女，竟成了上门女婿！',
  '岳母：你就是我家养的一条狗，得知女婿身份后，岳母：我的好女婿',
  '妻子被辱，穷小子意外重生，归来继承千亿家产开启复仇！恶人慌了',
  '女神被下糖，保安一个电话叫来8000保镖，酒店集合!',
  '女总裁公司面临破产，保安老公一个电话，1000亿资金到账！',
  '建议晚上看的短剧，一次性看个够，有点意思',
  '从小被人嘲笑的保安，竟然有个顶级总裁美女娇妻？',
  '一定要看第7集，真的是良心好剧，贼过瘾！',
  '乡下保安遭众人瞧不起，总裁娇妻迷恋，高能反转！',
  '五年了，全城都知道她是他的禁忌，却不知每夜他想她到失眠',
  '都以为我是个穷保安，都看不起我，其实我才是真正的大佬！',
  '小姐看中穷小子，奈何穷小子心中只有妻子便出口拒绝了！',
  '逆袭爽剧，保安的竟是豪门继承人，摇身一变成豪门少爷！',
  '“老爷，当年救您的神医下山了”“什么？快让小姐带百亿去求婚”',
  '兄弟果然没骗我！这里的小姐姐都好好看看完整版，看不停！',
  '穷小子下山退婚，却被京城第一美女总裁捉住：“叫媳妇儿！',
  '“拿着这些钱给我消失”岳母惊呆：什么废物女婿全是误会',
  '妻子手误把他的勋章发到网上，全世界一片震惊，那个男人回来了！',
  '吃软饭的上门赘婿受尽鄙视，揭开身份后岳母丈人瞬间跪求原谅！',
  '公司破产，总裁带保镖还债，谁知债主看到保镖竟吓得吞下欠条',
  '三年前被迫害入狱，绝望中自毁双目，无意间竟开启神眼血脉！',
  '本以为他只是路边乞丐，却意外成为一代王者，从此开启逆袭之路',
  '三年前，他受尽岳母非人羞辱，晚宴全场富豪给他敬酒，岳母后悔？',
  '女儿求救，战神殿三千强者，九大战神，四大天王从天而降！',
  '丈母娘寿宴，女婿步行被嘲，保安：谁把飞机停门口？',
  '边疆战神回归，发现妻子奄奄一息，一个电话，整个龙城颤抖！',
  '她在他身边待了三年，他什么都给了她，唯独爱情，闭口不言',
  '战神返乡，坐头等舱被人嘲讽，无奈拿出胸前玉佩，众人吓傻',
  '小小神医为退婚逃命三千里，怎么最后老婆还越跑越多？',
  '他手下有三大神秘队伍，一狼牙二猎鹰，最后一支名字没人敢提！',
  '他被人嘲笑，从此踏上修仙之路修罗战神，不服来战！',
  '岳母寿宴，穷女婿被安排与狗一桌，妻子：和狗一桌他也配？',
  '大反转！战神回家，发现哥哥含冤九泉，一声令下，十万护卫奔来！',
  '穷女婿3年受尽丈母娘羞辱，晚宴上全城富豪给他敬酒，岳母',
  '谁想到前一天工地搬砖的人第二天会成为首富呢',
  '他出走五年人称战神，回家发现妻子受难，一条短信千亿集团破产！',
  '妻子被辱，窝囊老公一个电话叫来8000特种兵，岳母一家直接吓哭',
  '这一世，他不再为无情而战！他是一代战神，只为找到她……',
  '妻子被辱，穷女婿一个电话叫来8000保镖，岳母一家直接吓哭',
  '战神归来，发现老婆孩子住狗窝，一声令下，十万将士奔来',
  '边境战神回归，身边只带四个兄弟，个个是令边境敌军吓跪！',
  '女婿含泪离婚，第二天妻子公司被收购，见到新老板妻子当场傻眼',
  '回家发现妻子受难，一条短信千亿集团破产！{#无上战神#}',
  '女婿被安排角落吃饭，首富竟来给他敬酒！岳母一家惊呆了',
  '蜕变战神的他归来，身披无数马甲横扫一切',
  '拿爸爸的破证件换钱后，8万护卫连夜赶来',
  '勇闯都市.绝世神医回归都市，却被美女总裁堵住去路…',
  '31岁以上的人千万要看，这部剧内容太精彩了，熬夜也要看完！',
  '妻子捡破烂，女儿住茅房，他怒了，一声令下，锦衣卫连夜奔来！',
  '“老爷，战王离婚了”，“什么！快让小姐带100亿去求婚！”',
  '5秒就上瘾，没有人能抗住这部剧，特别是第九集',
  '妻子欠下900亿带老公赴宴赔笑，不料债主看到他吓得竟吞了欠条',
  '新剧、热剧在线观看，赶紧点击视频链接继续观看更多精彩内容',
  '五年前，他是战神，五年后，他强势归来，却是一代战神……',
  '战神从监区带回三个守卫，看似弱不禁风，实际上是各界前十高手。',
  '一代战神回归都市，只为复仇，再次回归都市……#无上战神',
  '一口气看了十几集，差点一口气没上来，紧张死我了！看的心跳加速',
  '【新剧抢先看】，原来是在这里看剧，太上头了好看！',
  '“给我查！是谁撤走了公司90亿股份！””小姐，是刚离婚的老公”',
  '9.3分高分小说！都市战神，冰山美女，全文惊艳，不可错过!',
  '999辆法拉利封锁机场，上百名安保肃立整齐，恭迎大少爷回家！',
  '同学聚会，年薪十万一桌，百万一桌，穷女婿却问：一百亿坐哪儿？',
  '回国第一天，遭保安管家羞辱，谁知她竟是百亿总裁，吓傻了',
  '他四岁被神秘老人带走，入狱整整15年，殊不知老人权势滔天...',
  '做人留一线，日后好相见！看富二代如何作死自己',
  '上门女婿闹离婚，一个神秘证件，豪门岳父立马下跪喊到：少主！',
  '于八部战将而言，他是传奇，是偶像，是绝代战神！#无上战神',
  '接到妻子书信，君王暴怒，四大护法，十大战神，一夜齐赴大夏',
  '妻子参加同学聚会遭非人羞辱，一个电话飞机来接！',
  '一代战神回归都市，看他如何重返都市，再踏仙途！',
  '于八部战将而言，他是传奇，是他们的偶像，是绝代战神',
  '女儿被辱，穷女婿一个电话叫来8000保镖，岳母一家直接吓哭！',
  '2022公认爆火的短剧，8万人打出9.5高分，还在涨！',
  '权势通天的传奇战神寻到他，欲叫他指挥战神殿，征战天下。',
  '这到底是什么剧啊，让我爸睡觉看，吃饭看，上厕所也要看？',
  '他的名字是整座城的禁忌，听说他回来了，十大家主激动落泪：老大',
  '八荒战神来临，身边人竟然不知，结局逆袭成999亿身家高富帅！',
  '五年前她给了穷小子一块豆腐，五年后他低调回归，竟发生这样的事',
  '一代战神混入婚宴吃白食，竟意外拍到新郎劈腿，险救女总裁于危难',
  '喜欢看短剧的，进来看看，现在几千款短剧任你挑！',
  '妻子受辱，十万黑衣战将席卷而来，诺大繁华都市一夜倾覆',
  '总裁抢救无效身亡，竟被穷小子一针救活，女儿带着3000亿来提亲',
  '美女老婆想为范建明洗衣服，却发现口袋的神秘证件，他尽然是将星',
  '结婚第二天，美女总裁竟发现这小子精通200国语言?还是古武高手',
  '结婚三年，他从未碰过妻子一下，离婚3天，妻子偶遇他，直接傻眼',
  '他是战神，无敌战神，这一世，他不再为无情而战！只为找到她……',
  '他低调回国，只带3位半神，一位玉皇，一位财神，最后阎王…',
  '“叫声老公，命都给你！”甜到你发慌的宠文，不看绝对后悔系列！',
  '老公，我不想再受欺负了！”穷小子为了妻子暴露绝世富豪身份！',
  '5秒就上头！还有这种短剧？同事蹲坑都在看，第六集爽翻天！',
  '100辆劳斯莱斯封锁机场，上千名保镖肃立整齐，恭迎少爷回家！',
  '穷小伙被妻子嫌弃，离婚第二天，就被富婆领到公司当总裁！',
  '给我查！我这穷女婿到底什么身份？“老爷，他不得了啊！”',
  '刚出狱的小叔子被嫂嫂带去参加宴会，一进门各大佬吓傻:战..战王',
  '不满18岁便肩抗五星，被誉为东方第一战神，一身才华冠绝当世',
  '三任前夫死了以后，她咬牙嫁给了村里的穷小子，全村都惊呆了',
  '神医入市，凭一手鬼谷奇术与医术，却被美女总裁堵住去路…',
  '9.3分高分小说！都市神医，冰山美女，全文惊艳，不可错过!',
  '岳母挑五星酒店羞辱女婿,下一秒，穷女婿买下了整栋楼！',
  '“给我查，我丈夫到底是什么身份？”小姐！他...不得了啊！',
  '穷小子下山投奔师姐，没想到师姐一个比一个厉害，各个堪称绝色！',
  '罗斯柴尔德家族对他惟命是尊，华国第一战神，一身才华冠绝当世！',
  '为救女儿他跪求前妻反被侮辱，不料林阳爆出真实身份后前妻吓傻了',
  '妻子为还债，拿丈夫虎牙换钱，鉴宝师见到竟颤抖说：战神令！',
  '龙王从监区带回两个暗卫，看似弱不禁风，实际上是各界前三高手',
  '美女董事长遭非人羞辱，谁料她一个电话，飞机来接！',
  '他曾是炎国股神，意外重生醉酒懒汉，凭前世记忆再次血洗股市！',
  '守边五年，收到妻子寄来的欠条，他怒了：十万龙卫听令',
  '900辆法拉利封锁机场，上百名保镖肃立整齐，恭迎大少爷回家！',
  '岳母瞧不起女婿，却不知自己巴结的人给女婿擦鞋都不配！',
  '于十万男儿而言，他是传奇，是他们的偶像，是绝代战神！',
  '5岁女儿生日宴，门外8000人齐喊：拜小主寿！单亲妈妈失声痛哭！',
  '劝你莫欺少年穷，谁想到前一天工地搬砖的人第二天会成为首富呢',
  '妻子受辱，隐忍三年的战神终于不再低调：起风了，吴家该破产了！',
  '“给我查，我那个窝囊老公什么来头”“小姐，他...我不敢说！”',
  '“孙儿，爷爷给你捡个老婆回来！”他抬头一看惊呆了！',
  '美女董事长误发消息，回她的竟是小保安！众人惊呆了',
  '穷女婿3年受尽丈母娘羞辱，晚宴上全城富豪给他敬酒，岳母后悔？',
  '2任前夫死后，她嫁给了路边乞丐，不料新婚当天他竟然是战王!',
  '战神回家发现侄女在工地搬砖！一声令下，华夏8000锦衣卫连夜奔来',
  '岳父寿宴，女婿被当众赶下餐桌，酒店老板大怒：“撤宴！”',
  '战神从监区带回三个守卫，看似弱不禁风，实际上是各界前十高手',
  '不败战神，身怀绝世神功，逆袭人生，逆袭之路！#无上战神',
  '边疆战神回归，发现妻子奄奄一息，一个电话，整个龙城颤抖',
  '两年前，林家千金被迫嫁给乞丐，谁知他居然是战神，全家惊呆！',
  '龙少归来发现美女被欺辱，一个电话封锁全城，吓呆众人！',
  '女总裁公司面临破产，保安老公一个电话，300亿资金到账！',
  '战神还乡，儿时青梅竹马已红杏出墙，这些年失去的他要全部拿回来',
  '院长吓得跪下了！抱女儿来治病的穷小子居然是华夏神龙门门主！',
  '他意外成为修罗战神，从此踏上修仙之路修罗战神，不服来战！',
  '爷爷被害身亡，他入赘姜家三年，势利老婆却不知他权势滔天！',
  '这部短剧太爽了，尤其是第三集，太过瘾，看了几百遍还想看！',
  '于八部战将而言，他是传奇，是他们的偶像，是绝代战神！',
  '三年前被害入狱，竟无意间开启透视神眼！他发誓，要复仇到底...',
  '他20岁入狱，8年后出狱，整个监区欢呼：那个煞星终于走了…',
  '头一次坐飞机，穷小子亮出证件，航班吓得延误8小时!',
  '不满18岁便肩抗五星，被誉为东方第一战神，一身才华冠绝当世。',
  '一代战神回归都市，看他如何重返都市，再踏仙途！#无上战神',
  '敢动她一分，我灭你满门，战神回归第一天，出手灭了四大家族！',
  '5年退役战王遭离婚,百般羞辱后才知他背景惊人,美艳妻子后悔当初',
  '战神归来，你负责貌美如花，我负责君临天下!',
  '9.3分高分短剧！都市战神，冰山美女，全文惊艳，不可错过!',
  '他是龙城第一少，谁知生日那天惹上了大麻烦！#热播短剧',
  '世界三大组织，麒麟营、白虎骑、狼牙王，统归他调遣',
  '戎马十载，一战封神！经典战役，你能否成为一代战神？#无上战神',
  '99集已完结上门女婿高能剧，10万人打出9.8高分，一口气看完！',
  '他重生回1992年，凭前世记忆开始神级投资，一年时间净赚五百亿',
  '重生回到1990年代，看他如何扭转乾坤，走上人生巅峰',
  '穷小子意外身亡重生1991年，用超前20年的记忆，一个月狂揽千亿',
  '小伙意外重生1988，凭借前生记忆咸鱼翻身，一月狂揽八千亿',
  '他「重生」回1988年，凭前世记忆开始神级投资，一年时间净赚百亿',
  '意外重生1990年，凭记忆疯狂结交大佬，一年时间拥有500栋楼',
  '意外重生1983年，凭记忆疯狂结交大佬，一年时间拥有500栋楼！',
  '导演真敢拍，这部短剧太过瘾了，让你停不下来！',
  '穷小子重生1990年，用超前30年的记忆，三天狂揽十个亿',
  '“什么？房价3000？你们还嫌贵？”重生1988年的他挥手买下10小区',
  '重活一世看我如何把握赚钱机会狂卷一个亿，开启开挂人生',
  '女总裁欠下5亿被催债，债主上门看到窝囊老公吓得瑟瑟发抖...',
  '终于找到这部短剧了，一口气免费追到大结局，超过瘾！',
  '一觉醒来，回到1985年，发现自己竟然变成了一个超级富豪！',
  '他重生1990年，凭前世记忆开始疯狂买房，一年时间拥有400栋楼！',
  '穷小子将存折上10元改成200000!然后去银行取钱,结果傻眼',
  '9月爆火的短剧，你还没看过吗？#重回1990',
  '穿越1992年的他，利用超前技术开始神级投资，秒变富豪',
  '居然重生到遍地黄金的八十年代，马上炒股买楼，布局互联网！',
  '穷小子意外身亡，穿越30年前，偷拿老家拆迁款炒股，狂揽八千亿！',
  '一觉醒来，穿越到1990年，会发生什么变化？',
  '小伙重回90年代，凭记忆赚大钱，仅用100狂赚3亿',
  '5秒就上瘾，同事加班熬夜看完，特别是第六集！',
  '平行穿越，凭前世记忆疯狂投资，短短一年从二十万翻到了78亿！',
  '穷小子意外身亡重生1990年，用超前30年的记忆，一个月狂揽八千亿',
  '穷小子意外死亡重生1990年，用超前20年的记忆，一个月狂揽8000亿',
  '穷小子意外身亡重生1990年，用超前20年的记忆，一个月狂揽千亿',
  '意外重生,漂亮老婆天天追着他打:你还有脸说这块地皮以后值6000?',
  '一定要看第9集，以牙还牙，爽翻天!',
  '重生回1990年，凭前世记忆疯狂“收破烂”，一年从五毛变8个亿！',
  '“给我查，我丈夫到底是什么身份？”小姐，他不得了啊！',
  '重生后，漂亮老婆天天追着他打：你还有脸说这块地皮以后值6000？',
  '穷小子意外身亡重生1992年，用超前30年的记忆，一个月狂揽八千亿',
  '他意外回1988年，凭前世记忆开始神级投资，一年时间净赚百亿',
  '重生回1990年,女儿嗷嗷待哺,老婆大骂:别做梦了,赶紧去赚奶粉钱!',
  '穷小子意外身亡重生1990年，用超前30年的记忆，三天狂揽九个亿',
  '重生选择不一样的人生？农村小伙从穷小子成为了富商',
  '“什么？白酒股票没人买？”重生90后的他直接成为当代股坛大鳄！',
  '重生后他回到都市当奶爸，还多了个美女老婆，宠妻一人',
  '2022惊艳问鼎：10年无人能超越，力挽狂澜成就一段传奇！！',
  '一觉醒来竟重生80年代，凭记忆半月成了万元户，还娶了校花当老婆',
  '小伙重生2000年，凭前世记忆疯狂买别墅，一年后拥有400个小区',
  '一场宿醉竟重回1992,凭记忆开始神级投资秒变千亿富豪',
  '重生后，妻子追着他打：什么富豪，你现在就是穷光蛋',
  '别再乱花钱了，上千新款好剧，进去逛逛！',
  '重生回到刚刚就业时,凭20年前记忆开始创业之路,半年净赚千亿',
  '天哪！这短剧也太让人上瘾了！一定要看第5集',
  '失去爱人二十年后穿越回到90年代，去弥补当年的过错',
  '重回1990，他竟逆天改命，一年时间净赚千亿？',
  '2022公认爆火的穿越短剧，10万人打出9.8高分，还在涨！',
  '难怪大家喜欢在这里看剧，原来好看又实在，还有上百款任你选！',
  '5秒就上瘾，没有人能抗住这部剧，特别是第五集!',
  '重生是个不务正业的穷小子，凭前世记忆疯狂投资，狂赚1000亿！',
  '全60集，男主重重逆袭的短篇爽剧，一口气看完！',
  '小伙意外重生1988，凭借前生记忆咸鱼翻身，一月狂揽八千亿！',
  '他重生回1990年，凭前世记忆开始神级投资，一年时间净赚五百亿',
  '他重生回到90年代，凭前世记忆开始神级投资，一年时间净赚五百亿',
  '男人穿越回1990成为百亿富翁，见证商战变化',
  '这里视频太好看了，款式多任意挑！好看又好玩，不满意随便换！',
  '霸道总裁重生当奶爸，凭前世记忆狂赚7个亿，带着萌娃一路开挂！',
  '如果有时光机，回到过去，你最想做什么？#重回1990',
  '终于找到这本小说了，一口气追到大结局，超过瘾！',
  '3秒就上瘾，没有人能抗住这部剧，特别是第8集',
  '如果给你一次重来的机会，你将如何选择？',
  '3秒就上瘾，没有人能抗住这部剧，特别是第七集',
  '意外重生1990年，凭记忆疯狂结交大佬，一年时间拥有500栋楼！',
  '5秒就上头，同事加班熬夜看完，特别是第五集！',
  '穷保安买单亮出黑卡，众人当场大吃一惊！原来他真实身份竟是！',
  '打脸!窝囊女婿签完离婚协议竟坐豪车潇洒离去!美艳娇妻后悔了!',
  '穷小子意外身亡重生1990年，用超前30年的记忆，一月狂揽六千亿！',
  '重生归来，他靠投资为妻女撑起一片天，重战江湖，登巅峰！',
  '穷小子意外身亡重生1988年，用超前30年的记忆，三天狂揽九个亿！',
  '看了第一集，想看第二集，看了第二集，根本停不下来的短剧...',
  '快来看看，保证你从来没见过这么有趣的视频！',
  '他重生回1990年，凭前世记忆开始神级投资，一年时间净赚八百亿',
  '他双眼失明寻名医40多年无药可医,穷小子只用一招让他跪地叫恩人',
  '一觉醒来回到90年代，是开心还是难过',
  '重生回90年代，凭前世记忆疯狂投资，短短一年从二十万翻到了78亿',
  '意外重生回到遍地黄金的90年代，看穷小子复刻30年后的商业帝国！',
  '什么？房价4000？你们还嫌贵？”重生1991的他挥手买下十个小区',
  '重生回2000年，凭前世记忆疯狂投资，短短一年从二十万翻到了78亿',
  '亿万总裁重生90年代，凭记忆带领全村发家致富！',
  '好家伙！我竟然被这部短剧征服了,我也就连刷四遍',
  '重生回到年代，看他如何扭转乾坤，走上人生巅峰',
  '穷小子意外身亡重生1990年，用超前年的记忆，一个月狂揽千亿',
  '小伙意外重生，凭借前生记忆咸鱼翻身，一月狂揽八千亿',
  '他「重生」回1990年，凭前世记忆开始神级投资，一年时间净赚百亿',
  '意外重生1990年，凭记忆疯狂结交大佬，一年时间拥有栋楼！',
  '穷小子重生年，用超前年的记忆，三天狂揽十个亿',
  '“什么？房价？你们还嫌贵？”重生年的他挥手买下小区',
  '女总裁欠下亿被催债，债主上门看到窝囊老公吓得瑟瑟发抖...',
  '一觉醒来，回到年，发现自己竟然变成了一个超级富豪！',
  '他重生年，凭前世记忆开始疯狂买房，一年时间拥有栋楼！',
  '穷小子将存折上元改成!然后去银行取钱,结果傻眼',
  '月爆火的短剧，你还没看过吗？',
  '穿越年的他，利用超前技术开始神级投资，秒变富豪',
  '穷小子意外身亡，穿越年前，偷拿老家拆迁款炒股，狂揽八千亿！',
  '一觉醒来，穿越到年，会发生什么变化？',
  '小伙重回年代，凭记忆赚大钱，仅用狂赚亿',
  '秒就上瘾，同事加班熬夜看完，特别是第六集！',
  '平行穿越，凭前世记忆疯狂投资，短短一年从二十万翻到了亿！',
  '一定要看第集，真的以牙还牙，爽翻天!',
  '穷小子意外身亡重生年，用超前年的记忆，一个月狂揽八千亿',
  '意外重生,漂亮老婆天天追着他打:你还有脸说这块地皮以后值?',
  '一定要看第集，以牙还牙，爽翻天!',
  '秒就上瘾，没有人能抗住这部剧，特别是第五集',
  '重生回年，凭前世记忆疯狂“收破烂”，一年从五毛变个亿！',
  '重生后，漂亮老婆天天追着他打：你还有脸说这块地皮以后值？',
  '他意外回年，凭前世记忆开始神级投资，一年时间净赚百亿',
  '三年之期已到,恭迎战王归位,岳母:他要是战王我就是神仙呢',
  '.分高分小说！都市神医，冰山美女，全文惊艳，不可错过!',
  '天哪！这短剧也太让人上瘾了！特别是第集',
  '重生回年,女儿嗷嗷待哺,老婆大骂:别做梦了,赶紧去赚奶粉钱!',
  '穷小子意外身亡重生年，用超前年的记忆，三天狂揽九个亿',
  '“什么？白酒股票没人买？”重生后的他直接成为当代股坛大鳄！',
  '惊艳问鼎：年无人能超越，力挽狂澜成就一段传奇！！',
  '一觉醒来竟重生年代，凭记忆半月成了万元户，还娶了校花当老婆',
  '小伙重生年，凭前世记忆疯狂买别墅，一年后拥有个小区',
  '妻子欠下亿带老公赴宴赔笑，不料债主看到他吓得竟吞了欠条',
  '一场宿醉竟重回,凭记忆开始神级投资秒变千亿富豪',
  '重生回到刚刚就业时,凭年前记忆开始创业之路,半年净赚千亿',
  '天哪！这短剧也太让人上瘾了！一定要看第集',
  '失去爱人二十年后穿越回到年代，去弥补当年的过错',
  '重回，他竟逆天改命，一年时间净赚千亿？',
  '公认爆火的穿越短剧，万人打出.高分，还在涨！',
  '秒就上瘾，没有人能抗住这部剧，特别是第五集!',
  '重生是个不务正业的穷小子，凭前世记忆疯狂投资，狂赚亿！',
  '全集，男主重重逆袭的短篇爽剧，一口气看完！',
  '小伙意外重生，凭借前生记忆咸鱼翻身，一月狂揽八千亿！',
  '他重生回年，凭前世记忆开始神级投资，一年时间净赚五百亿',
  '他重生回到年代，凭前世记忆开始神级投资，一年时间净赚五百亿',
  '男人穿越回成为百亿富翁，见证商战变化',
  '霸道总裁重生当奶爸，凭前世记忆狂赚个亿，带着萌娃一路开挂！',
  '如果有时光机，回到过去，你最想做什么？',
  '秒就上瘾，没有人能抗住这部剧，特别是第集',
  '秒就上瘾，没有人能抗住这部剧，特别是第七集',
  '意外重生年，凭记忆疯狂结交大佬，一年时间拥有栋楼！',
  '秒就上头，同事加班熬夜看完，特别是第五集！',
  '穷小子意外身亡重生年，用超前年的记忆，一月狂揽六千亿！',
  '穷小子意外身亡重生年，用超前年的记忆，三天狂揽九个亿！',
  '他重生回年，凭前世记忆开始神级投资，一年时间净赚八百亿',
  '他双眼失明寻名医多年无药可医,穷小子只用一招让他跪地叫恩人',
  '一觉醒来回到年代，是开心还是难过',
  '重生回年代，凭前世记忆疯狂投资，短短一年从二十万翻到了亿',
  '意外重生回到遍地黄金的年代，看穷小子复刻年后的商业帝国！',
  '什么？房价？你们还嫌贵？”重生的他挥手买下十个小区',
  '重生回年，凭前世记忆疯狂投资，短短一年从二十万翻到了亿',
  '亿万总裁重生年代，凭记忆带领全村发家致富！',
  '建议晚上看的短剧，一次性看个够，有点意思！',
  '穷儿媳身份惊人，这下有好戏看了！',
  '婆婆看不起的乡下儿媳，真实身份竟是豪门千金，震惊众人！',
  '爱看短剧和不爱看短剧的人都惊了，这也太好看了吧！',
  '她闪婚嫁入豪门，破了十代单传的规矩，婆婆笑疯了：给我宠！',
  '乡下儿媳遭众人瞧不起，却不知她是千亿集团总裁，高能反转！',
  '一口气看完100集，这部短剧你千万别点，怕你睡不着！',
  '老公家族遇危机，奶奶怪罪孙媳妇，没想到她一个电话解决！打脸！',
  '一定要看第6集，不看真的会后悔，别怪我没告诉你',
  '2022月爆火的潜力短剧，你还没看过吗？',
  '都市爽剧，一次性看了6次，特别是第三集',
  '“给我查，我那个窝囊儿媳什么来头”“夫人，她…我不敢说！”',
  '势利婆婆从不待见儿媳，如今亮出真实身份，震惊众人！',
  '精彩！一定要看第16集，真的以牙还牙，爽翻天!',
  '都以为我是个乡下丫头，看不起我，其实我是遗落农村的首富千金！',
  '全新影视连续剧，章章精彩，没有废话可言',
  '短篇小说：女主至死都未原谅男主系列，44章，已完结',
  '势利婆婆从不待见儿媳，却不知她是豪门千金，反转不断！',
  '一定要看第7集，真的是良心好剧，贼过瘾！#我的媳妇是总裁',
  '妻子负债8亿，为还债带老公去聚会，吓得商业大咖忙吞欠条',
  '短篇虐文46章，半小时看完，超虐好看喔',
  '豪横富二代故意找茬，却不知眼前的窝囊小姐竟是权势滔天的总裁',
  '短视频时代已到来，快来看看你的专属短视频吧！',
  '这才是良心短剧巨作，剧情太过精彩，根本停不下来！',
  '一定要看第6集，真的以牙还牙，爽翻天！#我的媳妇是总裁',
  '岳母嫌弃的窝囊儿媳，真实身份竟是万亿总裁，知道真相岳母吓瘫',
  '今年32了，一般的短剧情我不想看，这部短剧我看了个通宵！',
  '一口气看完90集，这部短剧慎点，怕你睡不着！',
  '势利婆婆从不待见儿媳，儿媳竟这样反击！反转不断',
  '一部被低估的短剧，连续看了5集，根本停不下来',
  '家族聚会，大家提议AA制，穷儿媳笑了笑说：这家酒店是我的！',
  '爱看短剧的停一停，强力推荐一部打脸爽剧，情节反转精彩值得一看',
  '豪横富二代故意找茬，却不知眼前的上门女婿竟是万亿总裁',
  '乡下儿媳被瞧不起，含泪签下离婚协议，恢复豪门身份，惊呆所有人',
  '有工作的人千万要看，尤其是看第三集，让人越看越停不下来',
  '“给我查，我那老婆的真实身份！”“少爷，她...她是千亿总裁”',
  '都以为我是个穷媳妇，看不起我，其实我才是真正的大佬！',
  '都市爽剧，一次性看了80集，根本不想停下来',
  '被叫了三年的乡下穷儿媳，因为老公的一句话，亮出身份震撼全城！',
  '“给我查，是谁一夜收购了我们集团？”“是被您赶出门的儿媳！”',
  '这部剧也太好看了！一口气看到大结局！真爽！',
  '窝囊儿媳嫁入三年，饱受欺凌，亮出身份后，岳母惊呆了',
  '2022还没看过这部短剧？那一整年的剧都白追了！',
  '一定要看第6集，真的以牙还牙，爽翻天！',
  '电影电视剧都看腻了？试试这部短剧吧',
  '经典短剧推荐！2022还没看过这部短剧？那一整年的剧都白追了！',
  '被迫嫁给富二代，没想到她竟是隐藏身份的富豪，她竟是……',
  '这部口碑爆棚的短剧，10万人评分9.9，一口气看到大结局',
  '拿着手里的离婚证，上门儿媳无奈地拿起电话：管家，接我回去吧',
  '穷小子赖上漂亮女总裁#2022年度爆火短剧',
  '这部短剧妙不释手，让你尖叫不停，一口气看了80集',
  '打脸爽剧，情节反转精彩，不看后悔！',
  '1000亿集团董事长见到穷女婿，竟低头鞠躬叫：少爷！妻子惊呆了!',
  '逆袭爽剧，送外卖的竟是豪门继承人，摇身一变成豪门女王！',
  '乡下儿媳真实身份竟是豪门千金，反转不断！',
  '热门短剧看不厌，来这里看看，看完根本停不下来！',
  '势利婆婆瞧不起穷儿媳，儿媳忍无可忍爆出真实身份，顿时吓傻！',
  '电影电视剧没意思了？来看看这部剧吧！#我的媳妇是总裁',
  '今年29了，一般的短剧情我不想看，这部短剧我看了个通宵！',
  '强推这部赘婿神作，从头看到尾，全程高能反转逆！',
  '公司新来的小丫头干呕不止，猛踹公司总裁，全体员工看傻眼！',
  '2022火爆网络的短剧，你还不知道？快来看看吧！',
  '后悔看的太快，2022爆款出圈短剧，强烈推荐！',
  '电影电视剧没意思了？来看看这部剧吧！',
  '小媳妇参加同学会被羞辱穷，神秘管家：小姐，这卡里有1000亿！',
  '婆婆带富二代准儿媳逼离婚，乡下儿媳当场怒砸100亿，婆婆懵了',
  '一日看了6次，让你尖叫不停的短剧，尤其是第二集',
  '100集已完结都市赘婿短剧，8万人评出9.8分，一直看到大结局！',
  '她闪婚嫁入豪门,破了十代单传的规矩,婆婆笑疯了：给我宠！',
  '从小被人嘲笑的上门女孩，竟然是隐藏身份的超级富二代？',
  '一部让人看了停不下来的短剧，不建议看第二遍，怕你情绪失控',
  '她闪婚嫁到首富家，破了十代单传的规矩，婆婆笑疯了：给我宠！',
  '让人3秒就喜欢看的高分神剧，没有人能抗住这部剧，特别是第六集',
  '经典短剧推荐！11月了还没看过这部短剧？那一整年的剧都白追了！',
  '2022年巅峰短剧，80集看完还想看，点击观看【完整版】',
  '一口气看到80集，这部短剧让我欲犹未尽，好剧本！',
  '总裁三代单传，没想到她一胎三宝，老爷子放话：给我往死里宠~',
  '2022年巅峰短剧，80集看完还想看，持续更新中！',
  '丈夫半夜上厕所，无意看到妻子的外卖工资条，当场吓呆！',
  '这短剧也太好看了，蹲厕两小时，看到腿都发酸了...',
  '逆袭爽剧，送外卖的竟是豪门继承人，摇身一变成豪门女王'
]

export default {
  wenan
}
